<template>
  <div id="app" style="padding: 0px">
    <div class="news-tab" style="margin: 5px">
      <div class="row">
        <div class="col-md-12" style="padding: 15px">
          <div class="card">
            <div class="card-header" v-if="$route.params.id == 0">
              เพิ่มโครงการวิจัย
            </div>
            <div class="card-header" v-else>แก้ไขโครงการวิจัย</div>
            <div class="card-body">
              <div class="container mt-3" style="display: block">
                <!-- <div style="display: flex; justify-content: flex-end">
          <div>
            <button type="button" class="btn btn-primary" @click="back()">
              ย้อนกลับ
            </button>
          </div>
        </div> -->

                <div class="row">
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >เลขสัญญา <span style="color: red">*</span></label
                    >
                    <input
                      v-model="info.contract"
                      type="text"
                      class="form-control form-control-md"
                      id="exampleInputPassword1"
                      placeholder="เลขสัญญา"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >ประเภททุน <span style="color: red">*</span></label
                    >
                    <select
                      class="form-select form-select-md mb-3"
                      v-model="info.type_of_funding_id"
                    >
                      <option
                        v-for="(d, i) in funds"
                        v-bind:value="d.id"
                        :key="i + 5"
                      >
                        {{ d.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >วันที่ลงนามสัญญา <span style="color: red">*</span></label
                    ><br />
                    <date-picker
                      v-model="info.signing_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1"
                      >ชื่อโครงการ (ภาษาไทย)
                      <span style="color: red">*</span></label
                    >
                    <input
                      v-model="info.project_name_th"
                      type="text"
                      class="form-control form-control-md"
                      id="exampleInputPassword1"
                      placeholder="ชื่อโครงการ (ภาษาไทย)"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1"
                      >ชื่อโครงการ (ภาษาอังกฤษ)
                      <span style="color: red">*</span></label
                    >
                    <input
                      v-model="info.project_name_en"
                      type="text"
                      class="form-control form-control-md"
                      id="exampleInputPassword1"
                      placeholder="ชื่อโครงการ (ภาษาอังกฤษ)"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >วันเริ่มโครงการ <span style="color: red">*</span></label
                    ><br />
                    <date-picker
                      v-model="info.start_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >วันสิ้นสุดโครงการ
                      <span style="color: red">*</span></label
                    ><br />
                    <date-picker
                      v-model="info.end_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group col-md-4"></div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >ชื่อนักวิจัย <span style="color: red">*</span></label
                    >
                    <!-- <model-list-select :list="authors"
                     v-model="info.leader"
                     option-value="name_th"
                     :custom-text="codeAndName"
                     :placeholder="info.leader">
  </model-list-select> -->
                    <input
                      v-model="info.leader"
                      type="text"
                      class="form-control form-control-md"
                      id="exampleInputPassword1"
                      placeholder="ชื่อนักวิจัย"
                      @keyup="filteredAuthor"
                    />
                    <div v-if="info.leader || filteredList.length > 0">
                      <ul
                        class="list-group"
                        v-for="(s, i) in filteredList"
                        v-bind:value="s.id"
                        :key="i + 1"
                      >
                        <div
                          class="
                            form-control
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <a
                            style="text-decoration: none; cursor: pointer"
                            @click="getname(s)"
                          >
                            {{ s.name_th }}</a
                          >
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >สาขาวิชานักวิจัย <span style="color: red">*</span></label
                    >
                    <input
                      v-model="info.field"
                      type="text"
                      class="form-control form-control-md"
                      id="exampleInputPassword1"
                      placeholder="สาขาวิชานักวิจัย"
                    />
                  </div>
                  <div class="form-group col-md-4"></div>
                  <div class="col-md-4 col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1"
                        >การมีส่วนร่วม <span style="color: red">*</span></label
                      >
                      <select
                        class="form-select form-select-md mb-3"
                        v-model="info.type_of_participation_id"
                      >
                        <option
                          v-for="(d, i) in participations"
                          v-bind:value="d.id"
                          :key="i"
                        >
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >สัดส่วนการมีส่วนร่วม
                      <span style="color: red">*</span></label
                    >
                    <input
                      v-model="info.proportion"
                      type="number"
                      class="form-control form-control-md"
                      id="exampleInputPassword1"
                      placeholder="สัดส่วนการมีส่วนร่วม"
                    />
                  </div>
                  <div
                    style="background-color: #ddcfe5"
                    class="row mt-3 mb-3"
                    v-for="(l, i) in authorlist"
                    :key="i"
                  >
                    <label class="mt-3">นักวิจัยคนที่ {{ i + 2 }}</label>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputPassword1">ชื่อนักวิจัย</label>
                        <input
                          v-model="l.name"
                          type="text"
                          class="form-control form-control-md"
                          id="exampleInputPassword1"
                          :placeholder="'ชื่อนักวิจัย'"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputPassword1">สาขานักวิจัย</label>
                        <input
                          v-model="l.field"
                          type="text"
                          class="form-control form-control-md"
                          id="exampleInputPassword1"
                          :placeholder="'สาขานักวิจัย'"
                        />
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputPassword1"
                          >การมีส่วนร่วม
                        </label>
                        <select
                          class="form-select form-select-md mb-3"
                          v-model="l.type_of_participation_id"
                        >
                          <option
                            v-for="(d, i) in participations"
                            v-bind:value="d.id"
                            :key="i"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <div class="form-group">
                        <label for="exampleInputPassword1">สัดส่วน (%) </label>
                        <input
                          v-model="l.proportion"
                          type="number"
                          min="1"
                          max="100"
                          class="form-control form-control-md"
                          id="exampleInputPassword1"
                          :placeholder="'สัดส่วน'"
                        />
                      </div>
                      <div class="col-md-4"></div>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                      <button
                        type="button"
                        class="btn btn-danger mb-3"
                        @click="deleteauthor(i)"
                      >
                        ลบนักวิจัย
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12"></div>
                  <button
                    v-if="authorlist.length < 9"
                    type="button"
                    class="btn btn-primary col-md-2 mt-3"
                    @click="addauthor()"
                  >
                    เพิ่มนักวิจัย
                  </button>
                  <div class="form-group col-md-10"></div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >งบประมาณ <span style="color: red">*</span></label
                    >
                    <input
                      v-model="info.budget"
                      type="text"
                      class="form-control form-control-md"
                      id="exampleInputPassword1"
                      placeholder="งบประมาณ"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >วันที่นำส่ง KPI ของโครงการ </label
                    ><br />
                    <date-picker
                      v-model="info.kpi_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >วันที่ขยายสัญญาครั้งที่ 1</label
                    ><br />
                    <date-picker
                      v-model="info.first_contract_start_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >วันที่หมดขยายสัญญาครั้งที่ 1</label
                    ><br />
                    <date-picker
                      v-model="info.first_contract_end_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >วันที่ขยายสัญญาครั้งที่ 2</label
                    ><br />
                    <date-picker
                      v-model="info.second_contract_start_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >วันที่หมดขยายสัญญาครั้งที่ 2</label
                    ><br />
                    <date-picker
                      v-model="info.second_contract_end_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1"
                      >วันที่ขยายนำส่ง KPI</label
                    ><br />
                    <date-picker
                      v-model="info.kpi_extension_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="exampleInputPassword1">วันที่ปิดโครงการ</label
                    ><br />
                    <date-picker
                      v-model="info.project_close_date"
                      format="DD-MM-YYYY"
                      valueType="format"
                    ></date-picker>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">หมายเหตุ</label>
                    <textarea
                      rows="3"
                      v-model="info.remark"
                      type="text"
                      class="form-control form-control-md"
                      id="exampleInputPassword1"
                      placeholder="หมายเหตุ"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="exampleInputPassword1">แนบเอกสาร</label>

                    <div class="input-group">
                      <input
                        type="file"
                        accept="application/pdf"
                        @change="onFileChange"
                        class="form-control form-control-md"
                        id="inputGroupFile04"
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                      />
                      <button
                        :disabled="!this.selectedFile"
                        @click="onUploadFile"
                        class="btn btn-primary"
                        type="button"
                      >
                        Upload
                      </button>
                    </div>

                    <table
                      class="table table-striped mt-3"
                      v-if="docs.length > 0"
                    >
                      <thead>
                        <tr>
                          <th scope="col">ชื่อไฟล์</th>
                          <th scope="col">จัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(dd, i) in docs" :key="i + 1">
                          <td>
                            <a :href="dd.path" target="_blank">{{ dd.name }}</a>
                          </td>
                          <td>
                            <button
                              class="btn btn-outline-danger"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteDocData"
                              data-backdrop="static"
                              data-keyboard="false"
                              @click="getdocid(dd.id)"
                            >
                              <i
                                class="fa fa-trash"
                                style="font-size: 12px"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style="display: flex; justify-content: flex-end">
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary mt-5"
                      @click="save()"
                    >
                      บันทึก</button
                    >&nbsp;
                    <button
                      type="button"
                      class="btn btn-secondary mt-5"
                      @click="back()"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>

                <!-- Modal Delete-->
                <div
                  class="modal fade"
                  id="deleteDocData"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="editDataLabel">
                          ยืนยันการลบข้อมูล
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="deleteDoc()"
                        >
                          ยืนยัน
                        </button>
                        <button
                          type="button"
                          id="closedeletedoc"
                          class="btn btn-danger"
                          data-bs-dismiss="modal"
                        >
                          ยกเลิก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Delete-->
    <div
      class="modal fade"
      id="deleteFunding"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editDataLabel">ยืนยันการลบข้อมูล</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              id="closedelete"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import NewsService from "../services/NewsService.js";
import axios from "axios";
import MasterService from "../services/MasterService";
import AuthorsService from "../services/AuthorsService";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ConvertService from "../services/ConvertService";
import FundingService from "../services/FundingService";
import "vue-search-select/dist/VueSearchSelect.css";
// import { ModelListSelect } from 'vue-search-select'
import LinkService from "../services/LinkService";

export default {
  name: "app",
  components: {
    DatePicker,
    // ModelListSelect
  },
  data() {
    return {
      data: [],
      content: "",
      info: { leader: "" },
      type: 0,
      news_type: [],
      selectedFile: "",
      filename: "Choose file",
      docs: [],
      docid: "",
      modalVisible: false,
      researchs: [],
      authors: [],
      participations: [],
      researchsdatabase: [],
      fields: [],
      authorstypes: [],
      authorlist: [],
      leader: "",
      customLabels,
      pageOfItems: [],
      search: "",
      filteredList: [],
      funds: [],
    };
  },
  methods: {
    searchdata() {
      this.getFundings(this.currentUser.id);
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getname(data) {
      this.leader = data.name_th;
      this.info.leader = data.name_th;
      this.info.field = data.type_of_field;
      this.filteredList = [];
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    deletedata() {
      var deletedata = {
        updated_by: this.currentUser.id,
      };
      FundingService.deleteFunding(this.info.id, deletedata).then((res) => {
        this.authorlist = res.data;
        document.getElementById("closedelete").click();
        this.getFundings(this.currentUser.id);
      });
    },
    back() {
      this.$router.push("/Funding");
    },
    getdocid(id) {
      this.docid = id;
    },
    getdatadelete(id) {
      this.info.id = id;
    },
    getdata(id) {
      this.filename = "";
      this.modalVisible = false;
      // console.log(type);
      if (id != 0) {
        FundingService.getPublishFunding(id, 2).then((res) => {
          this.authorlist = res.data;
        });
        FundingService.getFunding(id).then((res) => {
          // console.log(res.data);
          this.info = res.data;
          this.info.id == res.data.id;
          this.getDocs();
          if (this.info.signing_date != null) {
            this.info.signing_date = ConvertService.convertdate(
              this.info.signing_date
            );
          }
          if (this.info.start_date != null) {
            this.info.start_date = ConvertService.convertdate(
              this.info.start_date
            );
          }
          if (this.info.end_date != null) {
            this.info.end_date = ConvertService.convertdate(this.info.end_date);
          }
          if (this.info.kpi_date != null) {
            this.info.kpi_date = ConvertService.convertdate(this.info.kpi_date);
          }
          if (this.info.first_contract_start_date != null) {
            this.info.first_contract_start_date = ConvertService.convertdate(
              this.info.first_contract_start_date
            );
          }
          if (this.info.first_contract_end_date != null) {
            this.info.first_contract_end_date = ConvertService.convertdate(
              this.info.first_contract_end_date
            );
          }
          if (this.info.second_contract_start_date != null) {
            this.info.second_contract_start_date = ConvertService.convertdate(
              this.info.second_contract_start_date
            );
          }
          if (this.info.second_contract_end_date != null) {
            this.info.second_contract_end_date = ConvertService.convertdate(
              this.info.second_contract_end_date
            );
          }
          if (this.info.kpi_extension_date != null) {
            this.info.kpi_extension_date = ConvertService.convertdate(
              this.info.kpi_extension_date
            );
          }
          if (this.info.project_close_date != null) {
            this.info.project_close_date = ConvertService.convertdate(
              this.info.project_close_date
            );
          }
        });
      } else {
        this.info = {};
        this.authorlist = [];
        this.info.id = 0;
        this.info.leader = "";
        this.authorlist.push({
          name: "",
          field: "",
          type_of_participation_id: "",
          proportion: "",
        });
      }
      this.type = 1;
    },
    save() {
      console.log(this.info);
      if (this.info.contract == null) {
        alert("กรุณากรอกเลขสัญญา");
      } else if (this.info.type_of_funding_id == null) {
        alert("กรุณาเลือกประเภททุน");
      } else if (this.info.signing_date == null) {
        alert("กรุณากรอกวันที่ลงนามสัญญา");
      } else if (this.info.project_name_th == null) {
        alert("กรุณากรอกชื่อโครงการ (ภาษาไทย)");
      } else if (this.info.project_name_en == null) {
        alert("กรุณากรอกชื่อโครงการ (ภาษาอังกฤษ)");
      } else if (this.info.start_date == null) {
        alert("กรุณากรอกวันเริ่มโครงการ");
      } else if (this.info.end_date == null) {
        alert("กรุณากรอกวันสิ้นสุดโครงการ");
      } else if (this.info.leader == null) {
        alert("กรุณากรอกชื่อนักวิจัย");
      } else if (this.info.field == null) {
        alert("กรุณากรอกสาขาวิชานักวิจัย");
      } else if (this.info.type_of_participation_id == null) {
        alert("กรุณาเลือกประเภทการมีส่วนร่วม");
      } else if (this.info.proportion == null) {
        alert("กรุณากรอกสัดส่วนการมีส่วนร่วม");
      } else if (this.info.budget == null) {
        alert("กรุณากรอกงบประมาณ");
      }
      //  else if (this.info.kpi_date == null) {
      //   alert("กรุณากรอกวันที่นำส่ง KPI ของโครงการ");
      // } else if (this.info.first_contract_start_date == null) {
      //   alert("กรุณากรอกวันที่ขยายสัญญาครั้งที่ 1");
      // } else if (this.info.first_contract_end_date == null) {
      //   alert("กรุณากรอกวันที่หมดขยายสัญญาครั้งที่ 1");
      // } else if (this.info.second_contract_start_date == null) {
      //   alert("กรุณากรอกวันที่ขยายสัญญาครั้งที่ 2");
      // } else if (this.info.second_contract_end_date == null) {
      //   alert("กรุณากรอกวันที่หมดขยายสัญญาครั้งที่ 2");
      // } else if (this.info.kpi_extension_date == null) {
      //   alert("กรุณากรอกวันที่ขยายนำส่ง KPI");
      // } else if (this.info.project_close_date == null) {
      //   alert("กรุณากรอกวันที่ปิดโครงการ");
      // }
      else {
        var funding = {
          contract: this.info.contract,
          type_of_funding_id: this.info.type_of_funding_id,
          signing_date: ConvertService.createddate(this.info.signing_date),
          project_name_th: this.info.project_name_th,
          project_name_en: this.info.project_name_en,
          start_date: ConvertService.createddate(this.info.start_date),
          end_date: ConvertService.createddate(this.info.end_date),
          leader: this.info.leader,
          field: this.info.field,
          type_of_participation_id: this.info.type_of_participation_id,
          proportion: this.info.proportion,
          budget: this.info.budget,
          kpi_date: ConvertService.createddate(this.info.kpi_date),
          first_contract_start_date: ConvertService.createddate(
            this.info.first_contract_start_date
          ),
          first_contract_end_date: ConvertService.createddate(
            this.info.first_contract_end_date
          ),
          second_contract_start_date: ConvertService.createddate(
            this.info.second_contract_start_date
          ),
          second_contract_end_date: ConvertService.createddate(
            this.info.second_contract_end_date
          ),
          kpi_extension_date: ConvertService.createddate(
            this.info.kpi_extension_date
          ),
          project_close_date: ConvertService.createddate(
            this.info.project_close_date
          ),
          status_id: 1,
          remark: this.info.remark,
          created_by: this.currentUser.id,
          updated_by: this.currentUser.id,
        };
        // console.log(funding);
        if (this.info.id == 0) {
          FundingService.createFunding(funding).then((res) => {
            // console.log(res.data);
            this.info.id = res.data.id;
            for (let d = 0; d < this.authorlist.length; d++) {
              var authorlist = {
                funding_id: this.info.id,
                name: this.authorlist[d].name,
                field: this.authorlist[d].field,
                type_of_participation_id:
                  this.authorlist[d].type_of_participation_id,
                proportion: this.authorlist[d].proportion,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
              };
              FundingService.createPublicationFunding(authorlist).then(
                (res) => {
                  console.log(res.data);
                }
              );
            }
            for (let d = 0; d < this.docs.length; d++) {
              var doc = {
                name: this.docs[d].name,
                path: this.docs[d].path,
                funding_id: this.info.id,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
                updated_at: new Date(),
              };
              FundingService.createFundingDoc(doc).then((res) => {
                console.log(res.data);
              });
            }
          });
          alert("บันทึกสำเร็จ");
          this.getParticipations();
        } else {
          var deletedoc = {
            created_by: this.currentUser.id,
            updated_by: this.currentUser.id,
          };
          FundingService.deleteDocFunding(this.info.id, deletedoc).then(() => {
            // console.log(res.data);
          });
          FundingService.deletePublicationFunding(this.info.id).then(() => {
            // console.log(res.data);
          });
          FundingService.updateFunding(this.info.id, funding).then(() => {
            // console.log(res.data);
            for (let d = 0; d < this.authorlist.length; d++) {
              var authorlist = {
                funding_id: this.info.id,
                name: this.authorlist[d].name,
                field: this.authorlist[d].field,
                type_of_participation_id:
                  this.authorlist[d].type_of_participation_id,
                proportion: this.authorlist[d].proportion,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
              };
              FundingService.createPublicationFunding(authorlist).then(() => {
                // console.log(res.data);
              });
            }
            for (let d = 0; d < this.docs.length; d++) {
              var doc = {
                name: this.docs[d].name,
                path: this.docs[d].path,
                funding_id: this.info.id,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
                status_id: 1,
                updated_at: new Date(),
              };
              FundingService.createFundingDoc(doc).then(() => {
                // console.log(res.data);
              });
            }
          });
          alert("บันทึกสำเร็จ");
          this.getParticipations();
        }
      }
    },
    getFundings(type) {
      this.data = [];
      FundingService.getFundings(type, this.search).then((res) => {
        // console.log(res.data);
        this.data = res.data;
      });
    },
    getDocs() {
      FundingService.getDocFunding(this.info.id, 2).then((res) => {
        // console.log(res.data);
        this.docs = res.data;
      });
    },
    getNewTypes() {
      NewsService.getNewTypes().then((res) => {
        // console.log(res.data);
        this.news_type = res.data;
      });
    },
    deleteDoc() {
      // console.log(this.docid);
      for (let t = 0; t < this.docs.length; t++) {
        if (this.docs[t].id == this.docid) {
          this.docs.splice(t, 1);
          document.getElementById("closedeletedoc").click();
        }
      }
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
      this.filename = selectedFile.name;
      // console.log(selectedFile);
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      var http = LinkService.getLink() + "/upload?name=" + this.filename;
      // var http ='http://localhost:8080/upload'
      axios
        .post(http, formData)
        .then(() => {
          // console.log(res.data.name);
          this.docs.push({
            id: this.docs.length + 1,
            name: this.filename,
            path: LinkService.getLink() + "/uploads/" + this.filename,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getResearchs() {
      MasterService.getResearchs().then((res) => {
        // console.log(res.data);
        this.researchs = res.data;
      });
    },
    codeAndName(item) {
      console.log(item);
      return `${item.name_th}`;
    },
    getAuthors() {
      AuthorsService.getAuthors().then((res) => {
        // console.log(res.data);
        this.authors = res.data;
      });
    },
    getAuthorTypes() {
      MasterService.getAuthors().then((res) => {
        // console.log(res.data);
        this.authorstypes = res.data;
      });
    },
    getParticipations() {
      MasterService.getParticipations().then((res) => {
        this.participations = res.data;
      });
    },
    getResearchsDatabase() {
      MasterService.getResearchsDatabase().then((res) => {
        this.researchsdatabase = res.data;
      });
    },
    getFunds() {
      MasterService.getFunds().then((res) => {
        this.funds = res.data;
      });
    },
    getFields() {
      MasterService.getFields().then((res) => {
        this.fields = res.data;
      });
    },
    addauthor() {
      this.authorlist.push({
        name: "",
        field: "",
        type_of_participation_id: "",
        proportion: "",
      });
    },
    deleteauthor(id) {
      for (let a = 0; a < this.authorlist.length; a++) {
        if (a == id) {
          this.authorlist.splice(a, 1);
        }
      }
    },
    filteredAuthor() {
      this.filteredList = [];
      this.filteredList = this.authors.filter((post) => {
        // console.log(post.name_th);
        return post.name_th
          .toLowerCase()
          .includes(this.info.leader.toLowerCase());
      });
    },
  },
  mounted() {
    // console.log(this.$route.params.id);
    this.getdata(this.$route.params.id);
    this.getFundings(this.currentUser.id);
    this.getNewTypes();
    this.getFunds();
    this.getParticipations();
    this.getAuthors();
    this.filteredAuthor();
    if (this.currentUser.user_type_id != 1 && this.$route.params.id == 0) {
      AuthorsService.getAuthorByname(this.currentUser.first_name).then(
        (res) => {
          // console.log(res.data);
          this.getname(res.data[0]);
        }
      );
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    // filteredList() {
    //   return this.authors.filter((post) => {
    //     console.log(this.info.leader);
    //     this.filteredList = post.name_th.toLowerCase().includes(this.info.leader.toLowerCase());
    //   });
    // },
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
span {
  font-size: 1.5rem;
}
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}
.upload-button {
  width: 7rem;
  padding: 0.5rem;
  background-color: #278be9;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 1rem;
}

.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
.quillWrapper {
  margin: 0px;
}
.ql-toolbar {
  margin: 0px;
}
.input-group {
  margin: 0px;
}
.mx-calendar {
  margin: 0px;
  padding: 0px;
  height: 320px !important;
}
.mx-datepicker {
  margin: 0px;
}
.mx-input {
  height: calc(2.875rem + 2px);
}
.mx-input-wrapper {
  margin: 0px;
}
#app {
  font-size: 1.5rem;
}
.btn {
  font-size: 1.5rem;
}
.form-control-md {
  font-size: 1.5rem;
}
.form-select-md {
  font-size: 1.5rem;
}
.page-item.active .page-link {
  background-color: #c49dd8;
  border-color: #c49dd8;
}
jw-pagination {
  color: white;
}
.card {
  padding: 0;
  margin: 0px;
  width: 100% !important;
  max-width: 100%;
}
input,
div,
button {
  margin-top: 0px;
  font-size: 1.5rem;
}
th {
  font-size: 1.5rem;
}
td {
  font-size: 1.5rem;
}
.custom-file-label {
  height: 29px;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ddcfe5;
  border-color: #ddcfe5;
}
</style>