<template>
  <div id="app" style="padding: 0px">
    <div class="news-tab mt-5" style="margin: 5px">
      <h2>จัดการข่าวสาร</h2>
      <div class="container mt-3" style="display: block">
        <div style="display: flex; justify-content: flex-end">
          <div>
            <a :href="'/News/0'">
              <button type="button" class="btn btn-success">
                <i class="fa fa-plus" style="font-size: 12px"></i></button
            ></a>
          </div>
        </div>
        <table class="table table-striped mt-3">
          <thead>
            <tr>
              <th scope="col">รหัส</th>
              <th scope="col">ประเภทข่าว</th>
              <th scope="col">หัวข้อ</th>
              <th scope="col">สถานะ</th>
              <th scope="col">จัดการ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d, i) in pageOfItems" :key="i">
              <th scope="row">{{ d.id }}</th>
              <td>{{ d.news_type }}</td>
              <td>{{ d.topic }}</td>
              <td v-if="d.status_id == 1">ใช้งานอยู่</td>
              <td v-if="d.status_id == 0">ไม่ใช้งาน</td>
              <td>
                <a :href="'/News/' + d.id">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="getdata(d.id)"
                  >
                    <i class="fa fa-edit" style="font-size: 12px"></i></button
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
         <div class="row mt-10" align="right">
                  <div class="col-md-12">
                    <jw-pagination
                      :items="data"
                      @changePage="onChangePage"
                      :labels="customLabels"
                    ></jw-pagination>
                  </div>
                </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsService from "../services/NewsService.js";
import axios from "axios";
import LinkService from "../services/LinkService";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};


export default {
  name: "app",
  components: {},
  data() {
    return {
      data: [],
      content: "",
      info: {},
      type: 0,
      news_type: [],
      selectedFile: "",
      filename: "Choose file",
      docs: [],
      docid: "",
      modalVisible: false,
      customLabels,
      pageOfItems: [],
    };
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getdocid(id) {
      this.docid = id;
    },
    getdata(id) {
      this.modalVisible = false;
      // console.log(type);
      if (id != 0) {
        NewsService.getNew(id).then((res) => {
          // console.log(res.data);
          this.info = res.data;
          this.info.id == res.data.id;
          this.getDocs(this.info.id);
        });
      } else {
        this.info.id = 0;
        this.info.status = 1;
        this.getDocs(this.info.id);
      }
      this.type = 1;
      this.selectedFile = "";
      this.filename = "";
    },
    save() {
      console.log(this.info);
      if (this.info.news_type_id == "") {
        alert("กรุณาเลือกประเภทข่าว");
      } else if (this.info.topic == "") {
        alert("กรุณากรอกหัวข้อข่าว");
      } else if (this.info.description == "") {
        alert("กรุณากรอกรายละเอียด");
      } else {
        var news = {
          topic: this.info.topic,
          description: this.info.description,
          news_type_id: this.info.news_type_id,
          status: this.info.status,
          created_by: this.currentUser.id,
          updated_by: this.currentUser.id,
          updated_at: new Date(),
        };
        console.log(news);
        if (this.info.id == 0) {
          NewsService.createNew(news).then((res) => {
            console.log(res.data);
            var new_id = res.data.id;
            for (let d = 0; d < this.docs.length; d++) {
              var doc = {
                new_id: new_id,
                name: this.docs[d].name,
                path: this.docs[d].path,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
                updated_at: new Date(),
              };
              NewsService.createDoc(doc).then((res) => {
                console.log(res.data);
              });
            }
          });
          alert("บันทึกสำเร็จ");
        } else {
          NewsService.updateNew(this.info.id, news).then((res) => {
            console.log(res.data);
            NewsService.deleteDoc(this.info.id).then((res) => {
              console.log(res.data);
            });
            for (let d = 0; d < this.docs.length; d++) {
              var doc = {
                new_id: this.info.id,
                name: this.docs[d].name,
                path: this.docs[d].path,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
                updated_at: new Date(),
              };
              NewsService.createDoc(doc).then((res) => {
                console.log(res.data);
              });
            }
          });
          alert("บันทึกสำเร็จ");
        }
      }
    },
    getNews(type) {
      NewsService.getNews(type).then((res) => {
        // console.log(res.data);
        this.data = res.data;
      });
    },
    getDocs(type) {
      NewsService.getDocs(type).then((res) => {
        // console.log(res.data);
        this.modalVisible = true;
        this.docs = res.data;
        for (let d = 0; d < this.docs.length; d++) {
          this.docs[d].id = d + 1;
        }
        // console.log(this.docs);
      });
    },
    getNewTypes() {
      NewsService.getNewTypes().then((res) => {
        // console.log(res.data);
        this.news_type = res.data;
      });
    },
    deleteDoc() {
      // console.log(this.docid);
      for (let t = 0; t < this.docs.length; t++) {
        if (this.docs[t].id == this.docid) {
          this.docs.splice(t, 1);
          document.getElementById("closedelete").click();
        }
      }
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
      this.filename = selectedFile.name;
      // console.log(selectedFile);
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      var http =
        LinkService.getLink() + "/upload?name=" + this.selectedFile.name;
      // var http ='http://localhost:8080/upload'
      axios
        .post(http, formData)
        .then(() => {
          // console.log(res.data.name);
          this.docs.push({
            id: this.docs.length + 1,
            name: this.filename,
            path: LinkService.getLink() + "/uploads/" + this.filename,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getNews("");
    this.getNewTypes();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
span {
  font-size: 1.5rem;
}
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}
.upload-button {
  width: 7rem;
  padding: 0.5rem;
  background-color: #278be9;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 1rem;
}

.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
.quillWrapper {
  margin: 0px;
}
.ql-toolbar {
  margin: 0px;
}
.input-group {
  margin: 0px;
}
</style>