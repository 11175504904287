<template>
  <div id="app" style="padding: 0px">
    <div class="row container">
      <div class="col-md-12 mb-5">
        <div class="tab-container mt-5">
          <ul class="nav nav-tabs">
            <li
              class="nav-item ng-star-inserted"
              v-for="(t, i) in tabs"
              :key="i"
            >
              <a
                style="font-size: 1.8rem"
                :class="'nav-link ' + t.status"
                href="#tab"
                @click="gotocontent(t.id)"
                ><span></span><i :class="t.class"></i>
                {{ t.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-12 mb-5">
        <div class="toggle-content mt-3" style="display: block">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item" v-for="(t, i) in news" :key="i">
              <h2 class="accordion-header" :id="'heading' + i">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + i"
                  aria-expanded="false"
                  :aria-controls="'collapse' + i"
                >
                  {{ t.name }}
                </button>
              </h2>
              <div
                :id="'collapse' + i"
                :class="t.class"
                :aria-labelledby="'heading' + i"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="info" v-if="t.sub.length > 0">
                    <span
                      class="col1"
                      style="margin-top: 30px"
                      v-for="(n, i) in t.sub"
                      :key="i"
                    >
                      <i class="fa fa-play-circle" style="color: #ec8e0c"></i
                      >&nbsp;
                      <a
                        :href="'/newsict/' + n.id"
                        target="_blank"
                        style="text-decoration: none"
                      >
                        <span
                          class="head-newsStudent"
                          style="font-size: 1.8rem"
                          >{{ n.topic }}</span
                        > </a
                      ><span style="font-size: 1.5rem">
                        {{ getdate(n.created_at) }}</span
                      >
                      <!-- <span v-html="n.description"></span> -->
                      <br />
                    </span>
                  </div>
                  <div style="text-align: center" v-else>ไม่พบข้อมูล</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsService from "../services/NewsService.js";

export default {
  name: "app",
  components: {},
  data() {
    return {
      status: "active",
      tabs: [],
      tab: 1,
      news: [],
      selectedFile: "",
    };
  },
  computed: {},
  methods: {
    generated() {
      // NewsService.getNewTypes(1).then((res)=>{
      //   this.tabs = res.data
      //   this.gotocontent(1)
      //   console.log(this.tabs);
      // });
      //   console.log(res.data);
      // this.tabs.push(
      //   {
      //     id: 1,
      //     name: "ข่าวทั่วไป",
      //     status: "active",
      //     class: "fa fa-circle news-tab-icon ng-star-inserted",
      //   },
      //   {
      //     id: 2,
      //     name: "ทุนวิจัย",
      //     status: "",
      //   },
      //   {
      //     id: 3,
      //     name: "ดาวน์โหลดเอกสาร ",
      //     status: "",
      //   },
      //   {
      //     id: 4,
      //     name: "เอกสารประชุมกรรมการวิจัย",
      //     status: "",
      //   },
      //   {
      //     id: 5,
      //     name: "ข้อบังคับและประกาศต่างๆ",
      //     status: "",
      //   }
      // );
    },
    gotocontent(id) {
      console.log(id);
      this.tab = id;
      for (let t = 0; t < this.tabs.length; t++) {
        this.tabs[t].status = "";
        this.tabs[t].class = "";
        if (this.tabs[t].id == id) {
          this.tabs[t].status = "active";
          this.tabs[t].class = "fa fa-circle news-tab-icon ng-star-inserted";
        }
      }
      this.getsubNew(this.tab);
    },
    getdate(d) {
      let date_ob = new Date(d);
      let date = ("0" + date_ob.getDate()).slice(-2);
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      let year = date_ob.getFullYear();
      let hours = date_ob.getHours();
      let minutes = date_ob.getMinutes();
      // prints date & time in YYYY-MM-DD format
      return year + "-" + month + "-" + date + " " + hours + ":" + minutes;
    },
    getsubNew(id) {
      NewsService.getNewBySubID(id)
        .then((res) => {
          console.log(res.data);
          this.news = res.data;
          this.addcollapse();
        })
        .catch(() => {
          NewsService.getNewBynoSubID(id).then((res) => {
            console.log(res.data);
            this.news = res.data;
            this.addcollapse();
          });
        });
    },
    addcollapse() {
      for (let n = 0; n < this.news.length; n++) {
        if (n == 0) {
          this.news[n].class = " accordion-collapse collapse show";
        } else {
          this.news[n].class = " accordion-collapse collapse";
        }
      }
    },
  },
  mounted() {
    NewsService.getNewTypes(1).then((res) => {
      this.tabs = res.data;
      this.gotocontent(this.tabs[0].id);
      this.getsubNew(this.tabs[0].id);
      console.log(this.tabs);
    });
    this.getNews(1);
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}

input {
  font-size: 0.9rem;
}

input,
div,
button {
  margin-top: 2rem;
}
.accordion-button {
  font-size: 1.5rem;
}

.upload-button {
  width: 7rem;
  padding: 0.5rem;
  background-color: #278be9;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 1rem;
}

.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
.accordion-button:focus {
  background-color: #c0b4d2;
  color: #560e7b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #c0b4d2;
}
.accordion-button:not(.collapsed) {
  background: #c0b4d2;
  color: #560e7b;
  background-color: #c0b4d2;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #c0b4d2;
}
</style>