<template>
  <div class="mt-5">
    <div class="card-body">
      <img src="../assets/ict.png" class="center mb-3" style="width: 70px" />
      <div class="form-group">
        <input
          v-model="user.username"
          type="text"
          min="1"
          class="form-control form-control-md"
          id="username"
          placeholder="ชื่อผู้ใช้งาน"
        />
      </div>
      <div class="form-group">
        <input
          v-model="user.password"
          v-on:keyup.enter="signIn()"
          type="password"
          class="form-control form-control-md"
          id="password"
          placeholder="รหัสผ่าน"
        />
        <div class="d-grid gap-2 mt-3">
          <button class="btn btn-primary" @click="signIn()" type="button">
            เข้าสู่ระบบ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {
      user: [],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    signIn() {
      if (this.user.username == "" || this.user.username == null) {
        alert("กรุณากรอกชื่อผู้ใช้งาน");
      } else if (this.user.password == "" || this.user.password == null) {
        alert("กรุณากรอกรหัสผ่าน");
      } else {
        var user = {
          username: this.user.username,
          password: this.user.password,
        };
        this.$store.dispatch("auth/login", user).then(
          () => {
            this.loading = true;
            setTimeout(function () {
              location.reload();

              this.$router.push("/home");
            }, 500);
          },
          (error) => {
            alert("ชื่อหรือรหัสผ่านไม่ถูกต้อง");
            console.log(error);
            // this.loading = false;
            // this.message =
            //   (error.response &&
            //     error.response.data &&
            //     error.response.data.message) ||
            //   error.message ||
            //   error.toString();
          }
        );
      }
    },
  },
  mounted() {},
};
</script>

<style>
body {
  font-family: "Kanit" !important;
  color: black !important;
}
.card {
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 10px;
  color: black !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.form-control:focus {
  border-color: #a423e6;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #a423e6;
}
.form-control-md {
  font-size: 1.5rem;
}
.form-select-md {
  font-size: 1.5rem;
}
</style>