import http from "../http-common";

class PublicationService {
  getPublications(id,name) {
    return http.get('/publications?id='+id+'&&name='+name);
  }
  getPublication(id) {
    return http.get('/publications/'+id);
  }
  createPublication(data) {
    return http.post(`/publications`, data);
  }
  updatePublication(id,data) {
    return http.put(`/publications/${id}`, data);
  }
  createPublicationFunding(data) {
    return http.post(`/publication_funding_author`, data);
  }
  deletePublicationFunding(id) {
    return http.delete(`/publication_funding_author/${id}`);
  }
  getPublishFunding(id,type){
    return http.get(`/publication_funding_author/findPublishFunding?type=`+type+'&&id='+id);    
  }
  deletePublication(id,data) {
    return http.put(`/publications/delete/${id}`, data);
  }
  
}

export default new PublicationService();