import Vue from "vue";
import Router from "vue-router";
import graphtax from './components/graphtax.vue';
import Home from './components/Home.vue';
import Newsict from './components/Newsict.vue';
import Funding from './components/Funding.vue';
import Publications from './components/Publications.vue';
import News from './components/News.vue';
import User from './components/User.vue';
import MasterData from './components/MasterData.vue';
import Profile from './components/Profile.vue';
import EditFunding from './components/EditFunding.vue';
import EditPublications from './components/EditPublications.vue';
import ResearchDetails from './components/ResearchDetails.vue';
import Notification from './components/Notification.vue';
import Summary from './components/Summary.vue';
import NewsEdit from './components/NewsEdit.vue';
import NewUser from './components/NewUser.vue';

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/home",
      name: "home",
      component: Home
    },
    {
      path: "/graphtax/:id",
      alias: "/graphtax",
      name: "graphtax",
      component: graphtax
    },
    {
      path: "/newsict/:id",
      alias: "/newsict",
      name: "newsict",
      component: Newsict
    },
    {
      path: "/Funding",
      alias: "/Funding",
      name: "Funding",
      component: Funding
    },    
    {
      path: "/Funding/:id",
      alias: "/Funding",
      name: "Funding",
      component: EditFunding
    },
    {
      path: "/Publications",
      alias: "/Publications",
      name: "Publications",
      component: Publications
    },
    {
      path: "/Publications/:id",
      alias: "/Publications",
      name: "Publications",
      component: EditPublications
    },   
    {
      path: "/News",
      alias: "/News",
      name: "News",
      component: News
    }, 
    {
      path: "/News/:id",
      alias: "/News",
      name: "News",
      component: NewsEdit
    },
    {
      path: "/User",
      alias: "/User",
      name: "User",
      component: User
    },
    {
      path: "/MasterData",
      alias: "/MasterData",
      name: "MasterData",
      component: MasterData
    },
    {
      path: "/Profile",
      alias: "/Profile",
      name: "Profile",
      component: Profile
    },
    {
      path: "/ResearchDetails",
      alias: "/ResearchDetails",
      name: "ResearchDetails",
      component: ResearchDetails
    },
    {
      path: "/Notification",
      alias: "/Notification",
      name: "Notification",
      component: Notification
    },
    {
      path: "/Summary",
      alias: "/Summary",
      name: "Summary",
      component: Summary
    },
    {
      path: "/User/:id",
      alias: "/User",
      name: "User",
      component: NewUser
    },
    
  ]
});


router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/home');
    // next();
  } else {
    next();
  }

});

export default router;
