<template>
  <div id="app" style="padding: 0px">
    <div class="news-tab" style="margin: 5px">
      <div class="row" v-if="type == 0" style="height: 600px">
        <div class="col-md-12" style="padding: 15px">
          <div class="card">
            <div class="card-header">ผลงานเผยแพร่</div>
            <div class="card-body">
              <div class="container mt-3" style="display: block">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-row align-items-center">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          v-model="search"
                          class="form-control form-control-md"
                          placeholder="ค้นหา"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />

                        <div class="input-group-append" style="margin-top: 0px">
                          <button
                            class="btn btn-info"
                            type="button"
                            style="color: white"
                            @click="searchdata()"
                          >
                            <i class="fa fa-search" style="font-size: 12px"></i>
                          </button>
                        </div>
                        &nbsp;&nbsp;
                        <div class="input-group-append" style="margin-top: 0px">
                          <button type="button" class="btn btn-primary">
                            <a href="/Publications/0">
                              <i class="fa fa-plus" style="font-size: 12px"></i
                            ></a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <table class="table table-striped mt-3">
                  <thead>
                    <tr>
                      <!-- <th scope="col">ประเภทผลงาน</th> -->
                      <!-- <th scope="col">วันที่เผยแพร่/จัดแสดงผลงาน</th> -->
                      <th scope="col">ชื่อผลงานวิจัย</th>
                      <!-- <th scope="col">ชื่อผลงาน (ภาษาอังกฤษ)</th> -->
                      <th scope="col">ชื่อนักวิจัยหลัก</th>
                      <!-- <th scope="col">สาขาวิชานักวิจัย</th>
              <th scope="col">การมีส่วนร่วม</th>
              <th scope="col">สัดส่วนการมีส่วนร่วม</th>
              <th scope="col">ชื่อวารสาร</th> -->
                      <!-- <th scope="col">ISSN</th>
              <th scope="col">ISBN</th>
              <th scope="col">ปีที่พิมพ์</th>
              <th scope="col">ฉบับที่</th>
              <th scope="col">สำนักพิมพ์</th>
              <th scope="col">หน้า</th>
              <th scope="col">ชื่อที่ประชุมวิชาการ</th>
              <th scope="col">สถานที่และพื้นที่จัดงาน</th>
              <th scope="col">ประเทศ</th>
              <th scope="col">วันเริ่มงาน</th>
              <th scope="col">วันสิ้นสุดงาน</th>
              <th scope="col">ฐานข้อมูลงานวิจัย</th> -->
                      <!-- <th scope="col">หมายเหตุ</th> -->
                      <th scope="col">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) in pageOfItems" :key="i">
                      <!-- <th scope="row">{{ d.type_of_research }}</th> -->
                      <!-- <td>{{ convertdatetothai(d.publication_date) }}</td> -->
                      <td>{{ d.name_th }}</td>
                      <!-- <td>{{ d.name_en }}</td> -->
                      <td>{{ d.leader }}</td>
                      <!-- <td>{{ d.field }}</td>
              <td>{{ d.type_of_author }}</td>
              <td>{{ d.proportion }}</td>
              <td>{{ d.journal_name }}</td> -->
                      <!-- <td>{{ d.issn }}</td>
              <td>{{ d.isbn }}</td>
              <td>{{ d.volume }}</td>
<td>{{ d.issue }}</td>
<td>{{ d.publisher }}</td>
<td>{{ d.page }}</td>
<td>{{ d.conference_name }}</td>
<td>{{ d.venue }}</td>
              <td>{{ d.country }}</td>
<td>{{ convertdatetothai(d.start_date) }}</td>
              <td>{{ convertdatetothai(d.end_date) }}</td>
              <td>{{ d.research_database  }}</td> -->
                      <!-- <td>{{ d.remark || "-" }}</td> -->
                      <td>
                        <a :href="'/Publications/' + d.id">
                          <button type="button" class="btn btn-outline-primary">
                            <i
                              class="fa fa-edit"
                              style="font-size: 12px"
                            ></i></button></a
                        >&nbsp;
                        <button
                          type="button"
                          class="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          @click="getdatadelete(d.id)"
                        >
                          <i class="fa fa-trash" style="font-size: 12px"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row mt-5" align="right">
                  <div class="col-md-12">
                    <jw-pagination
                      :items="data"
                      @changePage="onChangePage"
                      :labels="customLabels"
                    ></jw-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import NewsService from "../services/NewsService.js";
import axios from "axios";
import MasterService from "../services/MasterService";
import AuthorsService from "../services/AuthorsService";
import PublicationService from "../services/PublicationService";
import ConvertService from "../services/ConvertService";
import LinkService from "../services/LinkService";
export default {
  name: "app",
  components: {},
  data() {
    return {
      data: [],
      content: "",
      info: {},
      type: 0,
      news_type: [],
      selectedFile: "",
      filename: "Choose file",
      docs: [],
      docid: "",
      modalVisible: false,
      researchs: [],
      authors: [],
      participations: [],
      researchsdatabase: [],
      fields: [],
      authorstypes: [],
      authorlist: [],
      showdelete: false,
      customLabels,
      pageOfItems: [],
      search: "",
    };
  },
  methods: {
    searchdata() {
      if (this.currentUser.user_type_id == 1) {
        this.getPublications("");
      } else {
        this.getPublications(this.currentUser.id);
      }
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getdatadelete(id) {
      console.log();
      this.info.id = id;
    },
    deletedata() {
      var deletedata = {
        updated_by: this.currentUser.id,
      };
      PublicationService.deletePublication(this.info.id, deletedata).then(
        (res) => {
          this.authorlist = res.data;
          document.getElementById("closedelete").click();
          if (this.currentUser.user_type_id == 1) {
            this.getPublications("");
          } else {
            this.getPublications(this.currentUser.id);
          }
        }
      );
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    back() {
      this.type = 0;
      if (this.currentUser.user_type_id == 1) {
        this.getPublications("");
      } else {
        this.getPublications(this.currentUser.id);
      }
    },
    getdocid(id) {
      this.docid = id;
    },
    getdata(id) {
      this.modalVisible = false;
      // console.log(type);
      if (id != 0) {
        PublicationService.getPublishFunding(id, 1).then((res) => {
          this.authorlist = res.data;
        });
        PublicationService.getPublication(id).then((res) => {
          // console.log(res.data);
          this.info = res.data;
          this.info.id == res.data.id;
          if (this.info.publication_date != null) {
            this.info.publication_date = ConvertService.convertdate(
              this.info.publication_date
            );
          }
          if (this.info.start_date != null) {
            this.info.start_date = ConvertService.convertdate(
              this.info.start_date
            );
          }
          if (this.info.end_date != null) {
            this.info.end_date = ConvertService.convertdate(this.info.end_date);
          }
        });
      } else {
        this.info = {};
        this.info.id = 0;
        this.authorlist.push({
          name: "",
          field: "",
          type_of_author_id: "",
          proportion: "",
        });
      }
      this.type = 1;
    },
    save() {
      console.log(this.info);
      if (this.info.type_of_research_id == "") {
        alert("กรุณาเลือกประเภทผลงาน");
      } else if (this.info.publication_date == "") {
        alert("กรุณากรอกวันที่เผยแพร่/จัดแสดงผลงาน");
      } else if (this.info.name_th == "") {
        alert("กรุณากรอกชื่อผลงาน (ภาษาไทย)");
      } else if (this.info.name_en == "") {
        alert("กรุณากรอกชื่อผลงาน (ภาษาอังกฤษ)");
      } else if (this.info.leader == "") {
        alert("กรุณากรอกชื่อนักวิจัย");
      } else if (this.info.field == "") {
        alert("กรุณากรอกสาขาวิชานักวิจัย");
      } else if (this.info.type_of_participation_id == "") {
        alert("กรุณาเลือกประเภทการมีส่วนร่วม");
      } else if (this.info.proportion == "") {
        alert("กรุณากรอกสัดส่วนการมีส่วนร่วม");
      } else if (this.info.journal_name == "") {
        alert("กรุณากรอกชื่อวารสาร (พร้อมชื่อย่อ)");
      } else if (this.info.issn == "") {
        alert("กรุณากรอก ISSN");
      } else if (this.info.isbn == "") {
        alert("กรุณากรอก ISBN");
      } else if (this.info.volume == "") {
        alert("กรุณากรอกปีที่พิมพ์");
      } else if (this.info.issue == "") {
        alert("กรุณากรอกฉบับที่");
      } else if (this.info.publisher == "") {
        alert("กรุณากรอกสำนักพิมพ์");
      } else if (this.info.page == "") {
        alert("กรุณากรอกหน้า");
      } else if (this.info.conference_name == "") {
        alert("กรุณากรอกชื่อที่ประชุมวิชาการ (พร้อมชื่อย่อ)");
      } else if (this.info.venue == "") {
        alert("กรุณากรอกสถานที่และพื้นที่จัดงาน");
      } else if (this.info.country == "") {
        alert("กรุณากรอกประเทศ");
      } else if (this.info.start_date == "") {
        alert("กรุณากรอกวันเริ่มงาน");
      } else if (this.info.end_date == "") {
        alert("กรุณากรอกวันสิ้นสุดงาน");
      } else if (this.info.research_database_id == "") {
        alert("กรุณากรอกฐานข้อมูลงานวิจัย");
      } else {
        var publish = {
          type_of_research_id: this.info.type_of_research_id,
          publication_date: ConvertService.createddate(
            this.info.publication_date
          ),
          // publication_date:"2022-05-02",
          name_th: this.info.name_th,
          name_en: this.info.name_en,
          leader: this.info.leader,
          field: this.info.field,
          type_of_author_id: this.info.type_of_author_id,
          proportion: this.info.proportion,
          journal_name: this.info.journal_name,
          issn: this.info.issn,
          isbn: this.info.isbn,
          volume: this.info.volume,
          issue: this.info.issue,
          publisher: this.info.publisher,
          page: this.info.page,
          conference_name: this.info.conference_name,
          venue: this.info.venue,
          country: this.info.country,
          // start_date: "2020-08-01",
          // end_date: "2020-08-02",
          start_date: ConvertService.createddate(this.info.start_date),
          end_date: ConvertService.createddate(this.info.end_date),
          research_database_id: this.info.research_database_id,
          remark: this.info.remark,
          created_by: this.currentUser.id,
          updated_by: this.currentUser.id,
        };
        console.log(publish);
        if (this.info.id == 0) {
          PublicationService.createPublication(publish).then((res) => {
            console.log(res.data);
            this.info.id = res.data.id;
            for (let d = 0; d < this.authorlist.length; d++) {
              var authorlist = {
                publication_id: this.info.id,
                name: this.authorlist[d].name,
                field: this.authorlist[d].field,
                type_of_author_id: this.authorlist[d].type_of_author_id,
                proportion: this.authorlist[d].proportion,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
              };
              PublicationService.createPublicationFunding(authorlist).then(
                (res) => {
                  console.log(res.data);
                }
              );
            }
          });
          alert("บันทึกสำเร็จ");
          this.getParticipations();
        } else {
          PublicationService.deletePublicationFunding(this.info.id).then(() => {
            // console.log(res.data);
          });
          PublicationService.updatePublication(this.info.id, publish).then(
            () => {
              // console.log(res.data);
              for (let d = 0; d < this.authorlist.length; d++) {
                var authorlist = {
                  publication_id: this.info.id,
                  name: this.authorlist[d].name,
                  field: this.authorlist[d].field,
                  type_of_author_id: this.authorlist[d].type_of_author_id,
                  proportion: this.authorlist[d].proportion,
                  created_by: this.currentUser.id,
                  updated_by: this.currentUser.id,
                };
                PublicationService.createPublicationFunding(authorlist).then(
                  () => {
                    // console.log(res.data);
                  }
                );
              }
            }
          );
          alert("บันทึกสำเร็จ");
          this.getParticipations();
        }
      }
    },
    getPublications(type) {
      PublicationService.getPublications(type, this.search).then((res) => {
        console.log(res.data);
        this.data = res.data;
      });
    },
    getDocs(type) {
      NewsService.getDocs(type).then((res) => {
        // console.log(res.data);
        this.modalVisible = true;
        this.docs = res.data;
        for (let d = 0; d < this.docs.length; d++) {
          this.docs[d].id = d + 1;
        }
        // console.log(this.docs);
      });
    },
    getNewTypes() {
      NewsService.getNewTypes().then((res) => {
        // console.log(res.data);
        this.news_type = res.data;
      });
    },
    deleteDoc() {
      // console.log(this.docid);
      for (let t = 0; t < this.docs.length; t++) {
        if (this.docs[t].id == this.docid) {
          this.docs.splice(t, 1);
          document.getElementById("closedelete").click();
        }
      }
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
      this.filename = selectedFile.name;
      // console.log(selectedFile);
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      var http =
        LinkService.getLink() + "/upload?name=" + this.selectedFile.name;
      // var http ='http://localhost:8080/upload'
      axios
        .post(http, formData)
        .then(() => {
          // console.log(res.data.name);
          this.docs.push({
            id: this.docs.length + 1,
            name: this.filename,
            path: LinkService.getLink() + "/uploads/" + this.filename,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getResearchs() {
      MasterService.getResearchs().then((res) => {
        // console.log(res.data);
        this.researchs = res.data;
      });
    },
    getAuthors() {
      AuthorsService.getAuthors().then((res) => {
        // console.log(res.data);
        this.authors = res.data;
      });
    },
    getAuthorTypes() {
      MasterService.getAuthors().then((res) => {
        // console.log(res.data);
        this.authorstypes = res.data;
      });
    },
    getParticipations() {
      MasterService.getParticipations().then((res) => {
        this.participations = res.data;
      });
    },
    getResearchsDatabase() {
      MasterService.getResearchsDatabase().then((res) => {
        this.researchsdatabase = res.data;
      });
    },
    getFunds() {
      MasterService.getFunds().then((res) => {
        this.funds = res.data;
      });
    },
    getFields() {
      MasterService.getFields().then((res) => {
        this.fields = res.data;
      });
    },
    addauthor() {
      this.authorlist.push({
        name: "",
        field: "",
        type_of_author_id: "",
        proportion: "",
      });
    },
  },
  mounted() {
    if (this.currentUser.user_type_id == 1) {
      this.getPublications("");
    } else {
      this.getPublications(this.currentUser.id);
    }
    this.getNewTypes();
    this.getResearchs();
    this.getAuthors();
    this.getAuthorTypes();
    this.getResearchsDatabase();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
span {
  font-size: 1.5rem;
}
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}
.upload-button {
  width: 7rem;
  padding: 0.5rem;
  background-color: #278be9;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 1rem;
}

.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
.quillWrapper {
  margin: 0px;
}
.ql-toolbar {
  margin: 0px;
}
.input-group {
  margin: 0px;
}
.mx-calendar {
  margin: 0px;
  padding: 0px;
  height: 320px !important;
}
.mx-datepicker {
  margin: 0px;
}
.mx-input {
  height: calc(2.875rem + 2px);
}
.mx-input-wrapper {
  margin: 0px;
}
#app {
  font-size: 1.5rem;
}
.btn {
  font-size: 1.5rem;
}
.form-control-md {
  font-size: 1.5rem;
}
.form-select-md {
  font-size: 1.5rem;
}
.page-item.active .page-link {
  background-color: #c49dd8;
  border-color: #c49dd8;
}
.card {
  padding: 0;
  margin: 0px;
  width: 100% !important;
  max-width: 100%;
}
input,
div,
button {
  margin-top: 0px;
  font-size: 1.5rem;
}
th {
  font-size: 1.5rem;
}
td {
  font-size: 1.5rem;
}
.custom-file-label {
  height: 29px;
}
</style>