<template>
  <div style="padding: 5px"></div>
</template>

<script>
// import longdo from "longdo-map-vue";

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style>
html,
body {
  font-size: 9px;
  height: 100%;
  margin: 0px;
}
.ldmap_placeholder {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 100px;
  /* min-height: 620px !important; */
  background: #eee;
  font: 12px/1.2 Tahoma, sans-serif;
  overflow: hidden;
  z-index: 0;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
}
th {
  font-weight: normal;
}
</style>
