<template>
  <div id="app" style="padding: 0px">
    <div class="news-tab" style="margin: 5px">
      <div class="row">
        <div class="col-md-12" style="padding: 15px">
          <div class="card">
            <div class="card-header">ข้อมูลส่วนตัว</div>
            <div class="card-body">
              <div class="container mt-3" style="display: block">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">คำนำหน้า</label>
                      <input
                        v-model="info.title"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputPassword1">ชื่อ</label>
                      <input
                        v-model="info.first_name"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputPassword1">สกุล</label>
                      <input
                        v-model="info.last_name"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Email</label>
                      <input
                        v-model="info.email"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputPassword1">ตำแหน่ง</label>
                      <input
                        v-model="info.personnel_type_id"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="exampleInputPassword1">สังกัด </label>
                      <select
                        class="form-select form-select-md mb-3"
                        v-model="info.curriculum_id"
                      >
                        <option
                          v-for="(d, i) in type_of_field"
                          v-bind:value="d.id"
                          :key="i + 5"
                        >
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1"
                        >จำนวนเปเปอร์อ้างอิง</label
                      >
                      <input
                        v-model="info.research_cite"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">ค่า H-index</label>
                      <input
                        v-model="info.research_hindex"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">วุฒิปริญญาเอก</label>
                      <input
                        v-model="info.DD"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">วุฒิปริญญาโท</label>
                      <input
                        v-model="info.MD"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">วุฒิปริญญาตรี</label>
                      <input
                        v-model="info.BD"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                </div>
                <div style="display: flex; justify-content: flex-end">
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary mt-5"
                      @click="save()"
                    >
                      บันทึก</button
                    >&nbsp;
                    <button
                      type="button"
                      class="btn btn-secondary mt-5"
                      @click="back()"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MasterService from "../services/MasterService.js";
import UserService from "../services/UserService";

export default {
  name: "app",
  components: {},
  data() {
    return {
      info: {},
      type_of_field: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    getFields() {
      MasterService.getFields().then((res) => {
        this.type_of_field = res.data;
      });
    },
    getUser() {
      UserService.getUser(this.currentUser.id).then((res) => {
        this.info = res.data;
        // console.log(this.info);
      });
    },
  },
  mounted() {
    this.getFields();
    this.getUser();
  },
};
</script>

<style>
</style>