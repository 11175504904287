<template>
  <div id="app" style="margin: 0px; padding: 0px">
    <footer style="background-color: #560e7b">
      <div style="color: #ab87bd; font-size: 1.5rem">
        หน่วยวิจัยและนวัตกรรม คณะเทคโนโลยีสารสนเทศและการสื่อสาร มหาวิทยาลัยพะเยา
      </div>
      <div style="color: #ab87bd; font-size: 1.5rem">
        โทรศัพท์ 0544 666666 ต่อ 2327
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style>
</style>