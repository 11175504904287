import http from "../http-common";

class MasterService {
  getResearchs() {
    return http.get('/types_of_research');
  }
  getResearch(id) {
    return http.get('/types_of_research/'+id);
  }
  createResearch(data) {
    return http.post(`/types_of_research`, data);
  }
  updateResearch(id,data) {
    return http.put(`/types_of_research/${id}`, data);
  }
    deleteResearch(id) {
    return http.delete('/types_of_research/'+id);
  }
  getAuthors() {
    return http.get('/types_of_author');
  }
  getAuthor(id) {
    return http.get('/types_of_author/'+id);
  }
  createAuthor(data) {
    return http.post(`/types_of_author`, data);
  }
  updateAuthor(id,data) {
    return http.put(`/types_of_author/${id}`, data);
  }
    deleteAuthor(id) {
    return http.delete('/types_of_author/'+id);
  }
  getResearchsDatabase() {
    return http.get('/types_of_research_database');
  }
  getResearchDatabase(id) {
    return http.get('/types_of_research_database/'+id);
  }
  createResearchDatabase(data) {
    return http.post(`/types_of_research_database`, data);
  }
  updateResearchDatabase(id,data) {
    return http.put(`/types_of_research_database/${id}`, data);
  }
    deleteResearchDatabase(id) {
    return http.delete('/types_of_research_database/'+id);
  }
  getFunds() {
    return http.get('/types_of_fund');
  }
  getFund(id) {
    return http.get('/types_of_fund/'+id);
  }
  createFund(data) {
    return http.post(`/types_of_fund`, data);
  }
  updateFund(id,data) {
    return http.put(`/types_of_fund/${id}`, data);
  }
    deleteFund(id) {
    return http.delete('/types_of_fund/'+id);
  }
  getParticipations() {
    return http.get('/types_of_participation');
  }
  getParticipation(id) {
    return http.get('/types_of_participation/'+id);
  }
  createParticipation(data) {
    return http.post(`/types_of_participation`, data);
  }
  updateParticipation(id,data) {
    return http.put(`/types_of_participation/${id}`, data);
  }
    deleteParticipation(id) {
    return http.delete('/types_of_participation/'+id);
  }
  getFields() {
    return http.get('/types_of_field');
  }
  getField(id) {
    return http.get('/types_of_field/'+id);
  }
  createField(data) {
    return http.post(`/types_of_field`, data);
  }
  updateField(id,data) {
    return http.put(`/types_of_field/${id}`, data);
  }
    deleteField(id) {
    return http.delete('/types_of_field/'+id);
  }
  getNews() {
    return http.get('/news_type');
  }
  getNew(id) {
    return http.get('/news_type/'+id);
  }
  createNew(data) {
    return http.post(`/news_type`, data);
  }
  updateNew(id,data) {
    return http.put(`/news_type/${id}`, data);
  }
    deleteNew(id) {
    return http.delete('/news_type/'+id);
  }
  getPositions() {
    return http.get('/types_of_position');
  }
  getsubNew(id) {
    return http.get('/news_sub_type/'+id);
  }
  createsubNew(data) {
    return http.post(`/news_sub_type`, data);
  }
  updatesubNew(id,data) {
    return http.put(`/news_sub_type/${id}`, data);
  }
    deletesubNew(id) {
    return http.delete('/news_sub_type/'+id);
  }
}

export default new MasterService();