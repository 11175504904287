<template>
  <div id="app" style="padding: 0px">
    <div class="news-tab" style="margin: 5px">
      <h2>จัดการข่าวสาร</h2>
      <div class="container mt-3" style="display: block">
        <button type="button" class="btn btn-primary" @click="back()">
          ย้อนกลับ
        </button>
        <div class="form-group">
          <label for="exampleInputPassword1">ประเภทข่าวสาร</label>
          <select class="form-select form-select-md mb-3" v-model="info.news_type_id" @change="onchangesubnew">
            <option v-for="(d, i) in news_type" v-bind:value="d.id" :key="i">
              {{ d.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">หัวข้อย่อย</label>
          <select class="form-select form-select-md mb-3" v-model="info.news_sub_type_id">
            <option v-for="(d, i) in sub_news_type" v-bind:value="d.id" :key="i">
              {{ d.name }}
            </option>
          </select>
        </div>
        <div class="form-group mt-5">
          <label for="exampleInputPassword1">หัวข้อข่าว</label>
          <input
            v-model="info.topic"
            type="text"
            class="form-control form-control-md"
            id="exampleInputPassword1"
            placeholder="หัวข้อ"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">รายละเอียด</label>
          <vue-editor v-model="info.description" use-custom-image-handler
                @image-added="handleImageAdded"/>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">สถานะ</label>
          <select class="form-select form-select-md mb-3" v-model="info.status_id">
            <option value="1">ใช้งานอยู่</option>
            <option value="0">ไม่ใช้งาน</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="exampleInputPassword1">แนบเอกสาร</label>
          <div class="input-group">
               <input
                        type="file"
                        @change="onFileChange"
                        class="form-control form-control-md"
                        id="inputGroupFile04"
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                      />
                      <button
                        :disabled="!this.selectedFile"
                        @click="onUploadFile"
                        class="btn btn-primary"
                        type="button"
                      >
                        Upload
                      </button>
          </div>
        <table class="table table-striped mt-3" v-if="docs.length > 0">
          <thead>
            <tr>
              <th scope="col">ชื่อไฟล์</th>
              <th scope="col">จัดการ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dd, i) in docs" :key="i + 1">
              <td>
                <a :href="dd.path" target="_blank">{{ dd.name }}</a>
              </td>
              <td>
                <button
                  class="btn btn-outline-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteDocData"
                  data-backdrop="static"
                  data-keyboard="false"
                  @click="getdocid(dd.id)"
                >
                  <i class="fa fa-trash" style="font-size: 12px"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>        
        </div>
        <button type="button" class="btn btn-primary mt-3" @click="save()">
          บันทึก
        </button>

        <!-- Modal Delete-->
        <div
          v-if="modalVisible"
          class="modal fade"
          id="deleteDocData"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="editDataLabel">
                  ยืนยันการลบข้อมูล
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="deleteDoc()"
                >
                  ยืนยัน
                </button>
                <button
                  type="button"
                  id="closedelete"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  ปิด
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsService from "../services/NewsService.js";
import { VueEditor } from "vue2-editor";
import axios from "axios";
import LinkService from "../services/LinkService";

export default {
  name: "app",
  components: {
    VueEditor,
  },
  data() {
    return {
      data: [],
      content: "",
      info: {},
      type: 0,
      news_type: [],
      selectedFile: "",
      filename: "Choose file",
      docs: [],
      sub_news_type:[],
      docid: "",
      modalVisible: false,
    };
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      var http = LinkService.getLink()+"/upload?name=" + file.name;
      axios
        .post(http, formData)
        .then(() => {
      Editor.insertEmbed(
        cursorLocation,
        "image",
        LinkService.getLink()+"/uploads/" + file.name
      );
      resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onchangesubnew(evt){
      this.sub_news_type = []
// console.log(evt.target.value);
NewsService.getsubNew(evt.target.value).then((res)=>{
  console.log(res.data);
  this.sub_news_type = res.data
})
    },
    getdocid(id) {
      this.docid = id;
    },
    getdata(id) {
      this.modalVisible = false;
      // console.log(type);
      if (id != 0) {
        NewsService.getNew(id).then((res) => {
          // console.log(res.data);
          this.info = res.data;
          this.info.id == res.data.id;
          this.getDocs(this.info.id);
        });
      } else {
        this.info.id = 0;
        this.info.status_id = 1;
        this.getDocs(this.info.id);
      }
      this.type = 1;
      this.selectedFile = "";
      this.filename = "";
    },
    save() {
      console.log(this.info);
      if (this.info.news_type_id == "") {
        alert("กรุณาเลือกประเภทข่าว");
      } else if (this.sub_news_type.length > 0 && !this.info.news_sub_type_id) {
        alert("กรุณาเลือกหัวข้อย่อยของข่าว");
      }else if (this.info.topic == "") {
        alert("กรุณากรอกหัวข้อข่าว");
      } else if (this.info.description == "") {
        alert("กรุณากรอกรายละเอียด");
      } 
      else {
        var news = {
          topic: this.info.topic,
          description: this.info.description,
          news_type_id: this.info.news_type_id,
          status_id: this.info.status_id,
          news_sub_type_id:this.info.news_sub_type_id,
          created_by: this.currentUser.id,
          updated_by: this.currentUser.id,
          updated_at: new Date(),
        };
        console.log(news);
        if (this.info.id == 0) {
          NewsService.createNew(news).then((res) => {
            console.log(res.data);
            var news_id = res.data.id;
            for (let d = 0; d < this.docs.length; d++) {
              var doc = {
                news_id : news_id,
                name: this.docs[d].name,
                path: this.docs[d].path,
                type: this.docs[d].type,
                status_id :1,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
                updated_at: new Date(),
              };
              NewsService.createDoc(doc).then((res) => {
                console.log(res.data);
              });
            }
          alert("บันทึกสำเร็จ");
          this.$router.push("/News/"+news_id);
          });
        } else {
          NewsService.updateNew(this.info.id, news).then((res) => {
            console.log(res.data);
            NewsService.deleteDoc(this.info.id).then((res) => {
              console.log(res.data);
            });
            for (let d = 0; d < this.docs.length; d++) {
              var doc = {
                news_id: this.info.id,
                name: this.docs[d].name,
                path: this.docs[d].path,
                type: this.docs[d].type,
                status_id : 1,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
                updated_at: new Date(),
              };
              NewsService.createDoc(doc).then((res) => {
                console.log(res.data);
              });
            }
          });
          alert("บันทึกสำเร็จ");
          this.$router.push("/News/"+this.info.id);
        }
      }
    },
    getNews(type) {
      NewsService.getNews(type).then((res) => {
        console.log(res.data);
        this.data = res.data;
      });
    },
    getDocs(type) {
      NewsService.getDocs(type).then((res) => {
        console.log(res.data);
        this.modalVisible = true;
        this.docs = res.data;
        for (let d = 0; d < this.docs.length; d++) {
          this.docs[d].id = d + 1;
        }
        // console.log(this.docs);
      });
    },
    getNewTypes() {
      NewsService.getNewTypes(1).then((res) => {
        // console.log(res.data);
        this.news_type = res.data;
        if (this.$route.params.id != 0) {
        NewsService.getsubNew(res.data[0].id).then((res)=>{
  console.log(res.data);
  this.sub_news_type = res.data
})          
        }
      });
    },
    back() {
      this.$router.push("/News");
    },
    deleteDoc() {
      // console.log(this.docid);
      for (let t = 0; t < this.docs.length; t++) {
        if (this.docs[t].id == this.docid) {
          this.docs.splice(t, 1);
          document.getElementById("closedelete").click();
        }
      }
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
      this.filename = selectedFile.name;
      // console.log(selectedFile);
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      console.log(this.selectedFile);
      var http =
        LinkService.getLink() + "/upload?name=" + this.selectedFile.name;
      // var http ='http://localhost:8080/upload'
      axios
        .post(http, formData)
        .then(() => {
          // console.log(res.data.name);
          this.docs.push({
            id: this.docs.length + 1,
            name: this.filename,
            type:this.selectedFile.type,
            path: LinkService.getLink() + "/uploads/" + this.filename,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getdata(this.$route.params.id)
    this.getNews("");
    this.getNewTypes();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
span {
  font-size: 1.5rem;
}
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}
.upload-button {
  width: 7rem;
  padding: 0.5rem;
  background-color: #278be9;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 1rem;
}

.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
.quillWrapper {
  margin: 0px;
}
.ql-toolbar {
  margin: 0px;
}
.input-group {
  margin: 0px;
}
</style>