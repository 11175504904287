<template>
  <div class="container mb-5">
    <section class="main-content blog-single">
      <div class="container">
        <div class="row">
          <div class="post-wrap">
            <div class="col-md-12 mt-5">
              <h2>{{news.new_type_name}}</h2>
              <h3 v-if="news.sub_new_type_name">{{news.sub_new_type_name}}</h3>              
              <article class="mt-3">
                <div class="entry-wrapper mt-5">
                  <div class="post-content">
                    <h2>
                      <b
                        >{{ news.topic }} ({{
                          convertdatetothai(news.created_at)
                        }})
                      </b>
                    </h2>
                    <span
                      >วันเวลาที่โพส {{ convertdatetothai(news.created_at)
                      }}<span class="sep"></span>
                    </span>
                    <div class="mt-5">
                      <span
                        style="font-size: 1.5rem"
                        v-html="news.description"
                      ></span>
                    </div>

                    <div class="mt-5" v-if="docs.length > 0">
                      <p style="font-size: 1.5rem; font-weight: bold">
                        ดาวน์โหลดเอกสาร
                      </p>
                      <div v-for="d in docs" :key="d.id">
                        <p style="font-size: 1.6rem">
                          {{ d.name
                          }}<a :href="d.path"> ดาวน์โหลดเอกสารที่นี่</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NewsService from "../services/NewsService.js";
import axios from "axios";

export default {
  name: "app",
  components: {},
  data() {
    return {
      status: "active",
      news: {},
      id: "",
      docs: [],
    };
  },
  computed: {},
  methods: {
    getnew() {
      NewsService.getNew(this.id).then((res) => {
        console.log(res.data);
        this.news = res.data;
      });
    },
    getDoc() {
      NewsService.getDocs(this.id).then((res) => {
        console.log(res.data);
        this.docs = res.data;
      });
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result + " " + hours + ":" + minutes + " น.";
      } else {
        return "-";
      }
    },
    downloadFile() {
      axios({
        url: "https://codingshiksha.com/wp-content/uploads/2020/12/Screenshot_37-310x199.png",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getnew();
    this.getDoc();
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
span {
  font-size: 1.5rem;
}
</style>