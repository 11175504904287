<template>
  <div id="app" style="margin: 0px;height:100%">
    <nav
      class="navbar navbar-light"
      style="height: 50px; background-color: #f8f9fa"
    >
      <a class="navbar-brand" href="/" style="padding:5px">
        <img src="./assets/logo-leisure.png" height="30" alt="" />
      </a>
    </nav>
    <nav class="navbar-light" style="height: 110px; background-color: #560e7b">
       <div class="container-md">
    <a class="navbar-brand" href="#"></a>
  </div>
      <a class="navbar-brand " href="/">
      <span
        class="navbar-text"
        style="
          color: white;
          padding: 5px;
          font-size: 3.2rem !important;
          margin-left: 15px;
          margin-left: 15px;
          margin-top:10px
        "
      >
        ระบบจัดการงานวิจัย </span
      ><br />
      <span
        class="navbar-text"
        style="color: white; padding: 5px; font-size: 2rem!important; margin-left: 15px"
      >
        คณะเทคโนโลยีสารสนเทศและการสื่อสาร มหาวิทยาลัยพะเยา
      </span>
      </a>
    </nav>
    <div class="row" v-if="show">
      <div class="col-md-2" style="background-color: white" v-if="!currentUser">
        <Login />
      </div>
      <div
        class="col-md-2"
        style="background-color: #31353d"
        v-if="currentUser"
      >
        <div>
          <div class="left" style="background-color: #31353d;margin:5px">
            <h2 style="color: #818896;padding-left: 12px">{{currentUser.title}} {{currentUser.first_name}} {{currentUser.last_name}}</h2>
            <hr/>
            <h3 style="color: #818896;padding-left: 12px;padding-top: 12px">เมนู</h3>
            <ul id="myMenu">
              <li v-for="(m,i) in menu" :key="i">
                <a class="menutitle" :href="m.route">{{m.page}}</a>
              </li>
              <li>
                <a class="menutitle" @click.prevent="logOut" href="#"
                  >ออกจากระบบ</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-10">
        <router-view />
        <!-- <Home style="height: 100vh;overflow: auto;" v-if="page == 0" />
        <Funding v-if="page == 1" />
        <Publications v-if="page == 2" />
        <News v-if="page == 6" />
        <Authors v-if="false" />
        <Users v-if="page == 7" />
        <MasterData v-if="page == 8" />
        <Profile v-if="page == 9" /> -->
      </div>
    </div>
    <div class="row" v-else>
      <router-view />
    </div>
      <Footer />
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Login from "./components/Login.vue";
// import Home from "./components/Home.vue";
// import Profile from "./components/Profile.vue";
// import News from "./components/News.vue";
// import MasterData from "./components/MasterData.vue";
// import Users from "./components/Users.vue";
// import Publications from "./components/Publications.vue";
// import Authors from "./components/Authors.vue";
// import Funding from "./components/Funding.vue";
import UserService from "./services/UserService";

export default {
  name: "App",
  components: {
    Footer,
    Login,
    // Home,
    // Profile,
    // News,
    // MasterData,
    // Users,
    // Publications,
    // Authors,
    // Funding
  },
  data() {
    return {
      show: true,
      page: 0,
      menu:[]
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
      this.page = 0;
    },
    changpage(id) {
      this.page = id;
      this.$router.push("/");
    },
    getmenu(){
      UserService.getMenus(this.currentUser.user_type_id).then((res)=>{
        this.menu = res.data
        // console.log(this.menu);
      })
    }
  },
  mounted() {
    if (this.$route.name == "newsict") {
      this.show = false;
    }
    if (this.currentUser != null) {      
        this.getmenu()
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
.row {
  display: flex;
}

.menutitle {
  font-size: 1.7rem;;
  color: #818896 !important;
}
/* Left column (menu) */
.left {
  flex: 35%;
  padding: 15px 0;
}

/* Right column (page content) */
.right {
  flex: 65%;
  padding: 15px;
}

/* Style the navigation menu inside the left column */
#myMenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#myMenu li a {
  padding: 12px;
  text-decoration: none;
  color: black;
  display: block;
}

#myMenu li a:hover {
  background-color: #3a3f48;
}
.form-control-md{
font-size: 1.5rem;
}
.form-select-md{
font-size: 1.5rem;
}
div{
  font-size:1.5rem;
}
a{
  font-size: 1.5rem;
}
.row{
  width: 100%;
}
</style>