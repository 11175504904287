import http from "../http-common";

class NewsService {
  getNews(type) {
    return http.get('/news?type='+type);
  }
  getNewTypes(name) {
    return http.get('/news_type?name='+name);
  }
  getNew(id) {
    return http.get('/news/'+id);
  }
  createNew(data) {
    return http.post(`/news`, data);
  }
  updateNew(id,data) {
    return http.put(`/news/${id}`, data);
  }
  getDocs(id){
    return http.get('/news_document?id='+id);    
  }
  createDoc(data) {
    return http.post(`/news_document`, data);
  }
  deleteDoc(id) {
    return http.delete(`/news_document/`+ id);
  }
  getsubNew(id){
    return http.get('/news_sub_type/findByNewID/'+id);    
  }
  getNewBySubID(id){
    return http.get('/news/findBysubNewID/'+id);    
  }
  getNewBynoSubID(id){
    return http.get('/news/findBynosubNewID/'+id);    
  }
}

export default new NewsService();