<template>
  <div id="app" style="padding: 0px">
    <div class="news-tab mt-5" style="margin: 5px">
      <h2>ข้อมูลหลัก</h2>
      <div class="tab-container">
        <ul class="nav nav-tabs">
          <li class="nav-item ng-star-inserted" v-for="(t, i) in tabs" :key="i">
            <a
              style="font-size: 1.8rem"
              :class="'nav-link ' + t.status"
              id=""
              :href="'/MasterData?id=' + t.id"
              @click="getcontent(t.id)"
              ><span></span><i :class="t.class"></i>
              {{ t.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="mb-5">
        <div v-if="tab == 1" class="container mt-3" style="display: block">
          <div style="display: flex; justify-content: flex-end">
            <div>
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#editData"
                @click="getdata('research', 0)"
              >
                <i class="fa fa-plus" style="font-size: 12px"></i>
              </button>
            </div>
          </div>
          <table class="table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">รหัส</th>
                <th scope="col">{{ name }}</th>
                <th scope="col">ระดับภาระงาน</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in data" :key="i">
                <th scope="row">{{ d.code }}</th>
                <td>{{ d.name }}</td>
                <td>{{ d.workload_level || "-" }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#editData"
                  >
                    <i
                      class="fa fa-edit"
                      style="font-size: 12px"
                      @click="getdata('research', d.id)"
                    ></i></button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteData"
                    @click="getdata('research', d.id)"
                  >
                    <i class="fa fa-trash" style="font-size: 12px"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="tab == 2" class="container mt-3" style="display: block">
          <div style="display: flex; justify-content: flex-end">
            <div>
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#editData"
                @click="getdata('author', 0)"
              >
                <i class="fa fa-plus" style="font-size: 12px"></i>
              </button>
            </div>
          </div>
          <table class="table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">รหัส</th>
                <th scope="col">{{ name }}</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in data" :key="i">
                <th scope="row">{{ d.code }}</th>
                <td>{{ d.name }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#editData"
                    @click="getdata('author', d.id)"
                  >
                    <i class="fa fa-edit" style="font-size: 12px"></i></button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteData"
                    @click="getdata('author', d.id)"
                  >
                    <i class="fa fa-trash" style="font-size: 12px"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="tab == 3" class="container mt-3" style="display: block">
          <div style="display: flex; justify-content: flex-end">
            <div>
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#editData"
                @click="getdata('researchdatabase', 0)"
              >
                <i class="fa fa-plus" style="font-size: 12px"></i>
              </button>
            </div>
          </div>
          <table class="table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">รหัส</th>
                <th scope="col">{{ name }}</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in data" :key="i">
                <th scope="row">{{ d.id }}</th>
                <td>{{ d.name }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#editData"
                    @click="getdata('researchdatabase', d.id)"
                  >
                    <i class="fa fa-edit" style="font-size: 12px"></i></button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteData"
                    @click="getdata('researchdatabase', d.id)"
                  >
                    <i class="fa fa-trash" style="font-size: 12px"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="tab == 4" class="container mt-3" style="display: block">
          <div style="display: flex; justify-content: flex-end">
            <div>
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#editData"
                @click="getdata('fund', 0)"
              >
                <i class="fa fa-plus" style="font-size: 12px"></i>
              </button>
            </div>
          </div>
          <table class="table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">รหัส</th>
                <th scope="col">{{ name }}</th>
                <th scope="col">ระดับภาระงาน</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in data" :key="i">
                <th scope="row">{{ d.id }}</th>
                <td>{{ d.name }}</td>
                <td>{{ d.workload_level || "-" }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#editData"
                    @click="getdata('fund', d.id)"
                  >
                    <i class="fa fa-edit" style="font-size: 12px"></i></button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteData"
                    @click="getdata('fund', d.id)"
                  >
                    <i class="fa fa-trash" style="font-size: 12px"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="tab == 5" class="container mt-3" style="display: block">
          <div style="display: flex; justify-content: flex-end">
            <div>
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#editData"
                @click="getdata('par', 0)"
              >
                <i class="fa fa-plus" style="font-size: 12px"></i>
              </button>
            </div>
          </div>
          <table class="table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">รหัส</th>
                <th scope="col">{{ name }}</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in data" :key="i">
                <th scope="row">{{ d.id }}</th>
                <td>{{ d.name }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#editData"
                    @click="getdata('par', d.id)"
                  >
                    <i class="fa fa-edit" style="font-size: 12px"></i></button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteData"
                    @click="getdata('par', d.id)"
                  >
                    <i class="fa fa-trash" style="font-size: 12px"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="tab == 6" class="container mt-3" style="display: block">
          <div style="display: flex; justify-content: flex-end">
            <div>
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#editData"
                @click="getdata('field', 0)"
              >
                <i class="fa fa-plus" style="font-size: 12px"></i>
              </button>
            </div>
          </div>
          <table class="table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">รหัส</th>
                <th scope="col">{{ name }}</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in data" :key="i">
                <th scope="row">{{ d.id }}</th>
                <td>{{ d.name }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#editData"
                    @click="getdata('field', d.id)"
                  >
                    <i class="fa fa-edit" style="font-size: 12px"></i></button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteData"
                    @click="getdata('field', d.id)"
                  >
                    <i class="fa fa-trash" style="font-size: 12px"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="tab == 7" class="container mt-3" style="display: block">
          <div style="display: flex; justify-content: flex-end">
            <div>
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#editNewData"
                @click="getdata('new', 0)"
              >
                <i class="fa fa-plus" style="font-size: 12px"></i>
              </button>
            </div>
          </div>
          <table class="table mt-3">
            <thead>
              <tr>
                <th scope="col">รหัส</th>
                <th scope="col">{{ name }}</th>
                <th scope="col">สถานะ</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody v-for="(d, i) in data" :key="i">
              <tr style="background-color:#DBD2E0">
                <th scope="row">{{ d.id }}</th>
                <td>{{ d.name }}</td>
                <td v-if="d.status_id == 1">ใช้งานอยู่</td>
                <td v-if="d.status_id == 0">ไม่ใช้งาน</td>
                <td>
                   <button
                    type="button"
                    class="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#editNewsubData"
                    @click="getdata('new', d.id)"
                  >
                    <i class="fa fa-plus" style="font-size: 12px;color:green"></i></button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#editNewData"
                    @click="getdata('new', d.id)"
                  >
                    <i class="fa fa-edit" style="font-size: 12px"></i></button
                  >&nbsp;
                  <!-- <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteData"
                    @click="getdata('new', d.id)"
                  >
                    <i class="fa fa-trash" style="font-size: 12px"></i>
                  </button> -->
                </td>
              </tr>
               <tr v-for="(d, i) in d.sub" :key="i">
                <th scope="row"></th>
                <td>{{ d.name }}</td>
                <td v-if="d.status_id == 1">ใช้งานอยู่</td>
                <td v-if="d.status_id == 0">ไม่ใช้งาน</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#editNewsubData"
                    @click="getdatasub(d.id)"
                  >
                    <i class="fa fa-edit" style="font-size: 12px"></i></button
                  >&nbsp;
                  <!-- <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteNewsubData"
                    @click="getdatasub(d.id)"
                  >
                    <i class="fa fa-trash" style="font-size: 12px"></i>
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="editData"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="info.id == 0">เพิ่ม{{ name }}</h5>
            <h5 class="modal-title" v-else>แก้ไข{{ name }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              class="form-group"
              v-if="type == 'research' || type == 'author' || type == 'field'"
            >
              <label for="exampleInputEmail1"> รหัส</label>
              <input
                v-model="info.code"
                type="text"
                class="form-control form-control-md"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="รหัส"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">{{ name }}</label>
              <input
                v-model="info.name"
                type="text"
                class="form-control form-control-md"
                id="exampleInputPassword1"
                :placeholder="name"
              />
            </div>
            <div class="form-group" v-if="type == 'research' || type == 'fund'">
              <label for="exampleInputPassword1">ระดับภาระงาน</label>
              <input
                v-model="info.workload_level"
                type="number"
                class="form-control form-control-md"
                id="exampleInputPassword1"
                placeholder="ระดับภาระงาน"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save()">
              บันทึก
            </button>
            <button
              type="button"
              id="close"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="editNewsubData"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="infosub.id == 0">เพิ่มหัวข้อย่อย{{ info.name }}</h5>
            <h5 class="modal-title" v-else>แก้ไขหัวข้อย่อย{{ info.name }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="exampleInputPassword1">หัวข้อย่อย</label>
              <input
                v-model="infosub.name"
                type="text"
                class="form-control form-control-md"
                id="exampleInputPassword1"
                placeholder="หัวข้อย่อย"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">สถานะ</label>
              <select
                class="form-select form-select-md mb-3"
                v-model="infosub.status_id"
              >
                <option value="1">ใช้งานอยู่</option>
                <option value="0">ไม่ใช้งาน</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="savesub()">
              บันทึก
            </button>
            <button
              type="button"
              id="closenewsub"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="editNewData"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="info.id == 0">เพิ่ม{{ name }}</h5>
            <h5 class="modal-title" v-else>แก้ไข{{ name }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="exampleInputPassword1">{{ name }}</label>
              <input
                v-model="info.name"
                type="text"
                class="form-control form-control-md"
                id="exampleInputPassword1"
                :placeholder="name"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">สถานะ</label>
              <select
                class="form-select form-select-md mb-3"
                v-model="info.status_id"
              >
                <option value="1">ใช้งานอยู่</option>
                <option value="0">ไม่ใช้งาน</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save()">
              บันทึก
            </button>
            <button
              type="button"
              id="closenew"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Delete-->
    <div
      class="modal fade"
      id="deleteData"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editDataLabel">ยืนยันการลบข้อมูล</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              id="closedelete"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

        <!-- Modal Delete-->
    <div
      class="modal fade"
      id="deleteNewsubData"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editDataLabel">ยืนยันการลบข้อมูล</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="deletesubdata()">
              ยืนยัน
            </button>
            <button
              type="button"
              id="closedeletesub"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MasterService from "../services/MasterService";

export default {
  name: "app",
  components: {},
  data() {
    return {
      status: "active",
      tabs: [],
      tab: 1,
      data: [],
      selectedFile: "",
      info: {},
      type: "",
      name: "ประเภทผลงานวิจัย",
      infosub:{}
    };
  },
  computed: {},
  methods: {
    getdatasub(id){
MasterService.getsubNew(id).then((res)=>{
  this.infosub = res.data
})
    },
    getdata(type, id) {
      this.type = type;
      if (id != 0) {
        if (this.type == "research") {
          MasterService.getResearch(id).then((res) => {
            console.log(res.data);
            this.info = res.data;
          });
        } else if (this.type == "author") {
          MasterService.getAuthor(id).then((res) => {
            this.info = res.data;
          });
        } else if (this.type == "researchdatabase") {
          MasterService.getResearchDatabase(id).then((res) => {
            this.info = res.data;
          });
        } else if (this.type == "fund") {
          MasterService.getFund(id).then((res) => {
            this.info = res.data;
          });
        } else if (this.type == "par") {
          MasterService.getParticipation(id).then((res) => {
            this.info = res.data;
          });
        } else if (this.type == "field") {
          MasterService.getField(id).then((res) => {
            this.info = res.data;
          });
        } else if (this.type == "new") {
          MasterService.getNew(id).then((res) => {
            this.info = res.data;
            this.infosub = {}
            this.infosub.id = 0
            this.infosub.news_type_id = this.info.id
            this.infosub.status_id = 1
            // console.log(this.info);
          });
        }
      } else {
        this.info = {};
        this.info.id = 0;
      }
    },
    savesub(){
var subnew = {
          name: this.infosub.name,
          news_type_id:this.infosub.news_type_id,
          status_id: this.infosub.status_id,
        };
        console.log(this.infosub.id);
        console.log(subnew);
        if (this.infosub.id == 0) {
          MasterService.createsubNew(subnew).then((res) => {
            console.log(res.data);
            this.getNews();
            document.getElementById("closenewsub").click();
          });
        } else {
          MasterService.updatesubNew(this.infosub.id, subnew).then(() => {
            // console.log(res.data);
            this.getNews();
            document.getElementById("closenewsub").click();
          });
        }
    },
    save() {
      // console.log(this.type);
      // console.log(this.info);
      if (this.type == "research") {
        var research = {
          code: this.info.code,
          name: this.info.name,
          workload_level: this.info.workload_level,
        };
        if (this.info.id == 0) {
          MasterService.createResearch(research).then((res) => {
            console.log(res.data);
            this.getResearchs();
            document.getElementById("close").click();
          });
        } else {
          MasterService.updateResearch(this.info.id, research).then(() => {
            // console.log(res.data);
            this.getResearchs();
            document.getElementById("close").click();
          });
        }
      } else if (this.type == "author") {
        var author = {
          code: this.info.code,
          name: this.info.name,
        };
        if (this.info.id == 0) {
          MasterService.createAuthor(author).then((res) => {
            console.log(res.data);
            this.getAuthors();
            document.getElementById("close").click();
          });
        } else {
          MasterService.updateAuthor(this.info.id, author).then(() => {
            // console.log(res.data);
            this.getAuthors();
            document.getElementById("close").click();
          });
        }
      } else if (this.type == "researchdatabase") {
        var researchdatabase = {
          name: this.info.name,
        };
        if (this.info.id == 0) {
          MasterService.createResearchDatabase(researchdatabase).then((res) => {
            console.log(res.data);
            this.getResearchsDatabase();
            document.getElementById("close").click();
          });
        } else {
          MasterService.updateResearchDatabase(
            this.info.id,
            researchdatabase
          ).then(() => {
            // console.log(res.data);
            this.getResearchsDatabase();
            document.getElementById("close").click();
          });
        }
      } else if (this.type == "fund") {
        var fund = {
          name: this.info.name,
          workload_level: this.info.workload_level,
        };
        if (this.info.id == 0) {
          MasterService.createFund(fund).then((res) => {
            console.log(res.data);
            this.getFunds();
            document.getElementById("close").click();
          });
        } else {
          MasterService.updateFund(this.info.id, fund).then(() => {
            // console.log(res.data);
            this.getFunds();
            document.getElementById("close").click();
          });
        }
      } else if (this.type == "par") {
        var par = {
          name: this.info.name,
        };
        if (this.info.id == 0) {
          MasterService.createParticipation(par).then((res) => {
            console.log(res.data);
            this.getParticipations();
            document.getElementById("close").click();
          });
        } else {
          MasterService.updateParticipation(this.info.id, par).then(() => {
            // console.log(res.data);
            this.getParticipations();
            document.getElementById("close").click();
          });
        }
      } else if (this.type == "field") {
        var field = {
          code: this.info.code,
          name: this.info.name,
        };
        if (this.info.id == 0) {
          MasterService.createField(field).then((res) => {
            console.log(res.data);
            this.getFields();
            document.getElementById("close").click();
          });
        } else {
          MasterService.updateField(this.info.id, field).then(() => {
            // console.log(res.data);
            this.getFields();
            document.getElementById("close").click();
          });
        }
      } else if (this.type == "new") {
        var news = {
          name: this.info.name,
          status_id: this.info.status_id,
          updated_at: new Date(),
        };
        if (this.info.id == 0) {
          MasterService.createNew(news).then((res) => {
            console.log(res.data);
            this.getNews();
            document.getElementById("closenew").click();
          });
        } else {
          MasterService.updateNew(this.info.id, news).then(() => {
            // console.log(res.data);
            this.getNews();
            document.getElementById("closenew").click();
          });
        }
      }
    },
    deletedata() {
      console.log(this.type, this.info);
      if (this.type == "research") {
        console.log(this.info.id);
        MasterService.deleteResearch(this.info.id).then((res) => {
          console.log(res.data);
          this.getResearchs();
          document.getElementById("closedelete").click();
        });
      } else if (this.type == "author") {
        console.log(this.info.id);
        MasterService.deleteAuthor(this.info.id).then((res) => {
          console.log(res.data);
          this.getAuthors();
          document.getElementById("closedelete").click();
        });
      } else if (this.type == "researchdatabase") {
        console.log(this.info.id);
        MasterService.deleteResearchDatabase(this.info.id).then((res) => {
          console.log(res.data);
          this.getResearchsDatabase();
          document.getElementById("closedelete").click();
        });
      } else if (this.type == "fund") {
        console.log(this.info.id);
        MasterService.deleteFund(this.info.id).then((res) => {
          console.log(res.data);
          this.getFunds();
          document.getElementById("closedelete").click();
        });
      } else if (this.type == "par") {
        console.log(this.info.id);
        MasterService.deleteParticipation(this.info.id).then((res) => {
          console.log(res.data);
          this.getParticipations();
          document.getElementById("closedelete").click();
        });
      } else if (this.type == "field") {
        console.log(this.info.id);
        MasterService.deleteField(this.info.id).then((res) => {
          console.log(res.data);
          this.getFields();
          document.getElementById("closedelete").click();
        });
      } else if (this.type == "new") {
        console.log(this.info.id);
        MasterService.deleteNew(this.info.id).then((res) => {
          console.log(res.data);
          this.getNews();
          document.getElementById("closedelete").click();
        });
      }
    },
    deletesubdata() {
        console.log(this.infosub.id);
        MasterService.deletesubNew(this.infosub.id).then((res) => {
          console.log(res.data);
          this.getNews();
          document.getElementById("closedeletesub").click();
        });
    },
    generated() {
      this.tabs.push(
        {
          id: 1,
          name: "ประเภทผลงานวิจัย",
          status: "active",
          class: "fa fa-circle news-tab-icon ng-star-inserted",
        },
        {
          id: 2,
          name: "ชนิดของผู้นิพนธ์",
          status: "",
        },
        {
          id: 3,
          name: "ฐานข้อมูลงานวิจัย",
          status: "",
        },
        {
          id: 4,
          name: "ประเภททุน",
          status: "",
        },
        {
          id: 5,
          name: "ชนิดการมีส่วนร่วม",
          status: "",
        },
        {
          id: 6,
          name: "สาขาวิชา",
          status: "",
        },
        {
          id: 7,
          name: "ประเภทข่าว",
          status: "",
        }
      );
    },
    getcontent(id) {
      this.tab = id;
      for (let t = 0; t < this.tabs.length; t++) {
        this.tabs[t].status = "";
        this.tabs[t].class = "";
        if (this.tabs[t].id == id) {
          this.tabs[t].status = "active";
          this.tabs[t].class = "fa fa-circle news-tab-icon ng-star-inserted";
        }
      }
      if (this.tab == 1) {
        this.getResearchs();
        this.name = "ประเภทผลงานวิจัย ";
      } else if (this.tab == 2) {
        this.getAuthors();
        this.name = "ชนิดของผู้นิพนธ์";
      } else if (this.tab == 3) {
        this.getResearchsDatabase();
        this.name = "ฐานข้อมูลงานวิจัย ";
      } else if (this.tab == 4) {
        this.getFunds();
        this.name = "ประเภททุน";
      } else if (this.tab == 5) {
        this.getParticipations();
        this.name = "ชนิดการมีส่วนร่วม ";
      } else if (this.tab == 6) {
        this.getFields();
        this.name = "สาขาวิชา";
      } else if (this.tab == 7) {
        this.getNews();
        this.name = "ประเภทข่าว";
      }
    },
    getResearchs() {
      MasterService.getResearchs().then((res) => {
        // console.log(res.data);
        this.data = res.data;
      });
    },
    getAuthors() {
      MasterService.getAuthors().then((res) => {
        // console.log(res.data);
        this.data = res.data;
      });
    },
    getParticipations() {
      MasterService.getParticipations().then((res) => {
        this.data = res.data;
      });
    },
    getResearchsDatabase() {
      MasterService.getResearchsDatabase().then((res) => {
        this.data = res.data;
      });
    },
    getFunds() {
      MasterService.getFunds().then((res) => {
        this.data = res.data;
      });
    },
    getFields() {
      MasterService.getFields().then((res) => {
        this.data = res.data;
      });
    },
    getNews() {
      MasterService.getNews().then((res) => {
        console.log(res.data);
        this.data = res.data;
      });
    },
    getdate(d) {
      let date_ob = new Date(d);
      let date = ("0" + date_ob.getDate()).slice(-2);
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      let year = date_ob.getFullYear();
      let hours = date_ob.getHours();
      let minutes = date_ob.getMinutes();
      // prints date & time in YYYY-MM-DD format
      return year + "-" + month + "-" + date + " " + hours + ":" + minutes;
    },
  },
  mounted() {
    this.generated();
    this.getResearchs();
    console.log(this.$route.query.id);
    this.getcontent(this.$route.query.id);
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}
th {
  font-size: 1.8rem;
}
td {
  font-size: 1.8rem;
}
button {
  margin: 0px;
}
.btn-outline-primary {
  color: #560e7b;
  border-color: #560e7b;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.open > .dropdown-toggle.btn-outline-primary {
  color: #fff;
  background-color: #560e7b;
  border-color: #560e7b; /*set the color you want here*/
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #560e7b;
}
.btn-primary {
  background-color: #560e7b;
  border-color: #560e7b;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #560e7b;
  border-color: #560e7b; /*set the color you want here*/
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #560e7b;
}
input {
  margin: 0px;
}
.form-group {
  margin: 0px;
  margin-top: 10px;
}
.modal-body {
  margin: 0px;
}
.modal-header {
  margin: 0px;
}
#app {
  font-size: 1.5rem;
}
.btn {
  font-size: 1.5rem;
}
.form-control-md {
  font-size: 1.5rem;
}
.form-select-md {
  font-size: 1.5rem;
}
</style>