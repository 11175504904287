<template>
  <div id="app" style="padding: 0px">
    <div class="news-tab" style="margin: 5px">
      <div class="row">
        <div class="col-md-12" style="padding: 15px">
          <div class="card">
            <div class="card-header">ข้อมูลส่วนตัว</div>
            <div class="card-body">
              <div class="container mt-3" style="display: block">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">คำนำหน้า</label>
                      <input
                        v-model="info.title"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputPassword1">ชื่อ</label>
                      <input
                        v-model="info.first_name"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputPassword1">สกุล</label>
                      <input
                        v-model="info.last_name"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                   <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">ชื่อผู้ใช้งาน</label>
                      <input
                        v-model="info.username"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">สิทธิ์การใช้งาน </label>
                      <select
                        class="form-select form-select-md mb-3"
                        v-model="info.user_type_id"
                      >
                        <option
                          v-for="(d, i) in roles"
                          v-bind:value="d.id"
                          :key="i + 5"
                        >
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Email</label>
                      <input
                      required
                        v-model="info.email"
                        type="email"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputPassword1">ตำแหน่ง</label>
                       <select
                        class="form-select form-select-md mb-3"
                        v-model="info.personnel_type_id"
                      >
                        <option
                          v-for="(d, i) in personneltype"
                          v-bind:value="d.id"
                          :key="i + 5"
                        >
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="exampleInputPassword1">สังกัด </label>
                      <select
                        class="form-select form-select-md mb-3"
                        v-model="info.curriculum_id"
                      >
                        <option
                          v-for="(d, i) in type_of_field"
                          v-bind:value="d.id"
                          :key="i + 5"
                        >
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1"
                        >จำนวนเปเปอร์อ้างอิง</label
                      >
                      <input
                        v-model="info.research_cite"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">ค่า H-index</label>
                      <input
                        v-model="info.research_hindex"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">วุฒิปริญญาเอก</label>
                      <input
                        v-model="info.DD"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">วุฒิปริญญาโท</label>
                      <input
                        v-model="info.MD"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">วุฒิปริญญาตรี</label>
                      <input
                        v-model="info.BD"
                        type="text"
                        class="form-control form-control-md"
                        id="exampleInputPassword1"
                      />
                    </div>
                  </div>
                </div>
                <div style="display: flex; justify-content: flex-end">
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary mt-5"
                      @click="save()"
                    >
                      บันทึก</button
                    >&nbsp;
                    <button
                      type="button"
                      class="btn btn-secondary mt-5"
                      @click="back()"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MasterService from "../services/MasterService.js";
import UserService from "../services/UserService";

export default {
  name: "app",
  components: {},
  data() {
    return {
      info: {},
      type_of_field: [],
      roles:[],
      personneltype:[]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {    
     getUser() {
      UserService.getUser(this.info.id).then((res) => {
        this.info = res.data;
      });
    },
     getPersonnelType() {
      UserService.getPersonnelType().then((res) => {
        this.personneltype = res.data;
      });
    },
    getUsersType() {
      UserService.getUsersType().then((res) => {
        this.roles = res.data;
      });
    },
    getFields() {
      MasterService.getFields().then((res) => {
        this.type_of_field = res.data;
      });
    },
    save(){
      var user = {
title:this.info.title,
username :this.info.username,
email :this.info.email,
password :this.info.password,
first_name :this.info.first_name,
last_name :this.info.last_name,
user_type_id :this.info.user_type_id,
personnel_type_id  :this.info.personnel_type_id ,
MD :this.info.MD,
BD :this.info.BD,
DD :this.info.DD,
curriculum_id :this.info.curriculum_id,
research_cite :this.info.research_cite,
research_hindex :this.info.research_hindex,
status_id :1,
created_at :new Date(),
updated_at :new Date(),
      }
      console.log(user);
      if (this.info.id == 0) {
        UserService.createUser(user).then((res)=>{
        console.log(res.data);
        this.$router.push("/User");
      })
      }else{
        UserService.updateUser(this.info.id, user).then((res)=>{
        console.log(res.data);
        this.$router.push("/User");
      })
      }
      
    }
  },
  mounted() {
    this.getFields();
    this.getUsersType()
    this.getPersonnelType()
    this.info.id = this.$route.params.id
    if (this.info.id != 0) {
      this.getUser()
    }
  },
};
</script>

<style>
</style>