import http from "../http-common";

class PublicationService {
  getFundings(id,contract) {
    return http.get('/funding?id='+id+'&&contract='+contract);
  }
  getFunding(id) {
    return http.get('/funding/'+id);
  }
  createFunding(data) {
    return http.post(`/funding`, data);
  }
  updateFunding(id,data) {
    return http.put(`/funding/${id}`, data);
  }
  createPublicationFunding(data) {
    return http.post(`/publication_funding_author`, data);
  }
  deletePublicationFunding(id) {
    return http.delete(`/publication_funding_author/funding/${id}`);
  }
  getPublishFunding(id,type){
    return http.get(`/publication_funding_author/findPublishFunding?type=`+type+'&&id='+id);
  }
  deleteFunding(id,data) {
    return http.put(`/funding/delete/${id}`, data);
  }
  createFundingDoc(data) {
    return http.post(`/publications_funding_document`, data);
  }
  getDocFunding(id,type){
    return http.get(`/publications_funding_document?type=`+type+'&&id='+id);
  }
  deleteDocFunding(id,data) {
    return http.put(`/publications_funding_document/deletedocfunding/${id}`, data);
  }
}

export default new PublicationService();