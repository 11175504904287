import http from "../../http-common";

class AuthService {
  async login(user) {
    console.log(user)
    return http.post('user/signin', user)
      .then(response => {
        console.log(response.data);
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      },
        error => {
          console.log(error);
          this.$router.push("/login");
          alert('ชื่อหรือรหัสผ่านไม่ถูกต้อง')
        }
      );
  }

  logout() {
    localStorage.removeItem('user');
  }


}
export default new AuthService();