<template>
  <div id="app" style="padding: 0px">
    <div class="news-tab" style="margin: 5px">
      <div class="row" style="height: 600px">
        <div class="col-md-12" style="padding: 15px">
          <div class="card">
            <div class="card-header">โครงการวิจัย</div>
            <div class="card-body">
              <div class="container mt-3" style="display: block">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-row align-items-center">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          v-model="search"
                          class="form-control form-control-md"
                          placeholder="ค้นหา"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />

                        <div class="input-group-append" style="margin-top: 0px">
                          <button
                            class="btn btn-info"
                            type="button"
                            style="color: white"
                            @click="searchdata()"
                          >
                            <i class="fa fa-search" style="font-size: 12px"></i>
                          </button>
                        </div>
                        &nbsp;&nbsp;
                        <div class="input-group-append" style="margin-top: 0px">
                          <button type="button" class="btn btn-primary">
                            <a href="/Funding/0">
                              <i class="fa fa-plus" style="font-size: 12px"></i
                            ></a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <table class="table table-striped mt-5">
                  <thead>
                    <tr>
                      <th scope="col">เลขสัญญา</th>
                      <!-- <th scope="col">วันที่ลงนามสัญญา</th>
              <th scope="col">ชื่อโครงการ (ภาษาไทย)</th>
              <th scope="col">ชื่อโครงการ (ภาษาอังกฤษ)</th> -->
                      <!-- <th scope="col">วันเริ่มโครงการ</th>
              <th scope="col">วันสิ้นสุดโครงการ</th> -->
                      <!-- <th scope="col">ชื่อนักวิจัย</th> -->
                      <!-- <th scope="col">สาขาวิชานักวิจัย</th>
              <th scope="col">การมีส่วนร่วม</th>
              <th scope="col">สัดส่วนการมีส่วนร่วม</th>
              <th scope="col">งบประมาณ</th> -->
                      <!-- <th scope="col">วันที่นำส่ง KPI ของโครงการ</th>
              <th scope="col">วันที่ขยายสัญญาครั้งที่ 1</th>
              <th scope="col">วันที่หมดขยายสัญญาครั้งที่ 1</th>
              <th scope="col">วันที่ขยายสัญญาครั้งที่ 2</th>
              <th scope="col">วันที่หมดขยายสัญญาครั้งที่ 2</th>
              <th scope="col">วันที่ขยายนำส่ง KPI</th>
              <th scope="col">วันที่ปิดโครงการ</th> -->
                      <!-- <th scope="col">หมายเหตุ</th> -->
                      <th scope="col">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) in pageOfItems" :key="i">
                      <th scope="row">{{ d.contract }}</th>
                      <!-- <td>{{ convertdatetothai(d.signing_date) }}</td>
              <td>{{ d.project_name_th }}</td>
              <td>{{ d.project_name_en }}</td> -->
                      <!-- <td>{{ convertdatetothai(d.start_date) }}</td>
              <td>{{ convertdatetothai(d.end_date) }}</td> -->
                      <!-- <td>{{ d.leader }}</td> -->
                      <!-- <td>{{ d.field }}</td>
              <td>{{ d.type_of_participation }}</td>
              <td>{{ d.proportion }}</td>
              <td>{{ d.budget }}</td> -->
                      <!-- <td>{{ convertdatetothai(d.kpi_date) }}</td>
<td>{{ convertdatetothai(d.first_contract_start_date) }}</td>
<td>{{ convertdatetothai(d.first_contract_end_date) }}</td>
<td>{{ convertdatetothai(d.second_contract_start_date) }}</td>
<td>{{ convertdatetothai(d.second_contract_end_date) }}</td>
              <td>{{ convertdatetothai(d.kpi_extension_date) }}</td>
              <td>{{ convertdatetothai(d.project_close_date) }}</td> -->
                      <!-- <td>{{ d.remark || "-" }}</td> -->
                      <td>
                        <a :href="'/Funding/' + d.id">
                          <button type="button" class="btn btn-outline-primary">
                            <i
                              class="fa fa-edit"
                              style="font-size: 12px"
                            ></i></button></a
                        >&nbsp;
                        <button
                          type="button"
                          class="btn btn-outline-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteFunding"
                          @click="getdatadelete(d.id)"
                        >
                          <i class="fa fa-trash" style="font-size: 12px"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row mt-10" align="right">
                  <div class="col-md-12">
                    <jw-pagination
                      :items="data"
                      @changePage="onChangePage"
                      :labels="customLabels"
                    ></jw-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Delete-->
    <div
      class="modal fade"
      id="deleteFunding"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editDataLabel">ยืนยันการลบข้อมูล</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="deletedata()">
              ยืนยัน
            </button>
            <button
              type="button"
              id="closedelete"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

import NewsService from "../services/NewsService.js";
import axios from "axios";
import MasterService from "../services/MasterService";
import AuthorsService from "../services/AuthorsService";
import ConvertService from "../services/ConvertService";
import FundingService from "../services/FundingService";
import "vue-search-select/dist/VueSearchSelect.css";
// import { ModelListSelect } from 'vue-search-select'
import LinkService from "../services/LinkService";

export default {
  name: "app",
  components: {
    // ModelListSelect
  },
  data() {
    return {
      data: [],
      content: "",
      info: { leader: "" },
      type: 0,
      news_type: [],
      selectedFile: "",
      filename: "Choose file",
      docs: [],
      docid: "",
      modalVisible: false,
      researchs: [],
      authors: [],
      participations: [],
      researchsdatabase: [],
      fields: [],
      authorstypes: [],
      authorlist: [],
      leader: "",
      customLabels,
      pageOfItems: [],
      search: "",
      filteredList: [],
    };
  },
  methods: {
    searchdata() {
      if (this.currentUser.user_type_id == 1) {
        this.getFundings("");
      } else {
        this.getFundings(this.currentUser.id);
      }
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getname(data) {
      this.leader = data.name_th;
      this.info.leader = data.name_th;
      this.info.field = data.type_of_field;
      this.filteredList = [];
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return result;
      } else {
        return "-";
      }
    },
    deletedata() {
      var deletedata = {
        updated_by: this.currentUser.id,
      };
      FundingService.deleteFunding(this.info.id, deletedata).then((res) => {
        this.authorlist = res.data;
        document.getElementById("closedelete").click();
        if (this.currentUser.user_type_id == 1) {
          this.getFundings("");
        } else {
          this.getFundings(this.currentUser.id);
        }
      });
    },
    back() {
      this.type = 0;
      if (this.currentUser.user_type_id == 1) {
        this.getFundings("");
      } else {
        this.getFundings(this.currentUser.id);
      }
    },
    getdocid(id) {
      this.docid = id;
    },
    getdatadelete(id) {
      this.info.id = id;
    },
    getdata(id) {
      this.filename = "";
      this.modalVisible = false;
      // console.log(type);
      if (id != 0) {
        FundingService.getPublishFunding(id, 2).then((res) => {
          this.authorlist = res.data;
        });
        FundingService.getFunding(id).then((res) => {
          // console.log(res.data);
          this.info = res.data;
          this.info.id == res.data.id;
          this.getDocs();
          if (this.info.signing_date != null) {
            this.info.signing_date = ConvertService.convertdate(
              this.info.signing_date
            );
          }
          if (this.info.start_date != null) {
            this.info.start_date = ConvertService.convertdate(
              this.info.start_date
            );
          }
          if (this.info.end_date != null) {
            this.info.end_date = ConvertService.convertdate(this.info.end_date);
          }
          if (this.info.kpi_date != null) {
            this.info.kpi_date = ConvertService.convertdate(this.info.kpi_date);
          }
          if (this.info.first_contract_start_date != null) {
            this.info.first_contract_start_date = ConvertService.convertdate(
              this.info.first_contract_start_date
            );
          }
          if (this.info.first_contract_end_date != null) {
            this.info.first_contract_end_date = ConvertService.convertdate(
              this.info.first_contract_end_date
            );
          }
          if (this.info.second_contract_start_date != null) {
            this.info.second_contract_start_date = ConvertService.convertdate(
              this.info.second_contract_start_date
            );
          }
          if (this.info.second_contract_end_date != null) {
            this.info.second_contract_end_date = ConvertService.convertdate(
              this.info.second_contract_end_date
            );
          }
          if (this.info.kpi_extension_date != null) {
            this.info.kpi_extension_date = ConvertService.convertdate(
              this.info.kpi_extension_date
            );
          }
          if (this.info.project_close_date != null) {
            this.info.project_close_date = ConvertService.convertdate(
              this.info.project_close_date
            );
          }
        });
      } else {
        this.info = {};
        this.authorlist = [];
        this.info.id = 0;
        this.info.leader = "";
        this.authorlist.push({
          name: "",
          field: "",
          type_of_participation_id: "",
          proportion: "",
        });
      }
      this.type = 1;
    },
    save() {
      console.log(this.info);
      if (this.info.contract == null) {
        alert("กรุณากรอกเลขสัญญา");
      } else if (this.info.type_of_funding_id == null) {
        alert("กรุณาเลือกประเภททุน");
      } else if (this.info.signing_date == null) {
        alert("กรุณากรอกวันที่ลงนามสัญญา");
      } else if (this.info.project_name_th == null) {
        alert("กรุณากรอกชื่อโครงการ (ภาษาไทย)");
      } else if (this.info.project_name_en == null) {
        alert("กรุณากรอกชื่อโครงการ (ภาษาอังกฤษ)");
      } else if (this.info.start_date == null) {
        alert("กรุณากรอกวันเริ่มโครงการ");
      } else if (this.info.end_date == null) {
        alert("กรุณากรอกวันสิ้นสุดโครงการ");
      } else if (this.info.leader == null) {
        alert("กรุณากรอกชื่อนักวิจัย");
      } else if (this.info.field == null) {
        alert("กรุณากรอกสาขาวิชานักวิจัย");
      } else if (this.info.type_of_participation_id == null) {
        alert("กรุณาเลือกประเภทการมีส่วนร่วม");
      } else if (this.info.proportion == null) {
        alert("กรุณากรอกสัดส่วนการมีส่วนร่วม");
      } else if (this.info.budget == null) {
        alert("กรุณากรอกงบประมาณ");
      }
      //  else if (this.info.kpi_date == null) {
      //   alert("กรุณากรอกวันที่นำส่ง KPI ของโครงการ");
      // } else if (this.info.first_contract_start_date == null) {
      //   alert("กรุณากรอกวันที่ขยายสัญญาครั้งที่ 1");
      // } else if (this.info.first_contract_end_date == null) {
      //   alert("กรุณากรอกวันที่หมดขยายสัญญาครั้งที่ 1");
      // } else if (this.info.second_contract_start_date == null) {
      //   alert("กรุณากรอกวันที่ขยายสัญญาครั้งที่ 2");
      // } else if (this.info.second_contract_end_date == null) {
      //   alert("กรุณากรอกวันที่หมดขยายสัญญาครั้งที่ 2");
      // } else if (this.info.kpi_extension_date == null) {
      //   alert("กรุณากรอกวันที่ขยายนำส่ง KPI");
      // } else if (this.info.project_close_date == null) {
      //   alert("กรุณากรอกวันที่ปิดโครงการ");
      // }
      else {
        var funding = {
          contract: this.info.contract,
          type_of_funding_id: this.info.type_of_funding_id,
          signing_date: ConvertService.createddate(this.info.signing_date),
          project_name_th: this.info.project_name_th,
          project_name_en: this.info.project_name_en,
          start_date: ConvertService.createddate(this.info.start_date),
          end_date: ConvertService.createddate(this.info.end_date),
          leader: this.info.leader,
          field: this.info.field,
          type_of_participation_id: this.info.type_of_participation_id,
          proportion: this.info.proportion,
          budget: this.info.budget,
          kpi_date: ConvertService.createddate(this.info.kpi_date),
          first_contract_start_date: ConvertService.createddate(
            this.info.first_contract_start_date
          ),
          first_contract_end_date: ConvertService.createddate(
            this.info.first_contract_end_date
          ),
          second_contract_start_date: ConvertService.createddate(
            this.info.second_contract_start_date
          ),
          second_contract_end_date: ConvertService.createddate(
            this.info.second_contract_end_date
          ),
          kpi_extension_date: ConvertService.createddate(
            this.info.kpi_extension_date
          ),
          project_close_date: ConvertService.createddate(
            this.info.project_close_date
          ),
          status_id: 1,
          remark: this.info.remark,
          created_by: this.currentUser.id,
          updated_by: this.currentUser.id,
        };
        // console.log(funding);
        if (this.info.id == 0) {
          FundingService.createFunding(funding).then((res) => {
            // console.log(res.data);
            this.info.id = res.data.id;
            for (let d = 0; d < this.authorlist.length; d++) {
              var authorlist = {
                funding_id: this.info.id,
                name: this.authorlist[d].name,
                field: this.authorlist[d].field,
                type_of_participation_id:
                  this.authorlist[d].type_of_participation_id,
                proportion: this.authorlist[d].proportion,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
              };
              FundingService.createPublicationFunding(authorlist).then(
                (res) => {
                  console.log(res.data);
                }
              );
            }
            for (let d = 0; d < this.docs.length; d++) {
              var doc = {
                name: this.docs[d].name,
                path: this.docs[d].path,
                funding_id: this.info.id,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
                updated_at: new Date(),
              };
              FundingService.createFundingDoc(doc).then((res) => {
                console.log(res.data);
              });
            }
          });
          alert("บันทึกสำเร็จ");
          this.getParticipations();
        } else {
          var deletedoc = {
            created_by: this.currentUser.id,
            updated_by: this.currentUser.id,
          };
          FundingService.deleteDocFunding(this.info.id, deletedoc).then(() => {
            // console.log(res.data);
          });
          FundingService.deletePublicationFunding(this.info.id).then(() => {
            // console.log(res.data);
          });
          FundingService.updateFunding(this.info.id, funding).then(() => {
            // console.log(res.data);
            for (let d = 0; d < this.authorlist.length; d++) {
              var authorlist = {
                funding_id: this.info.id,
                name: this.authorlist[d].name,
                field: this.authorlist[d].field,
                type_of_participation_id:
                  this.authorlist[d].type_of_participation_id,
                proportion: this.authorlist[d].proportion,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
              };
              FundingService.createPublicationFunding(authorlist).then(() => {
                // console.log(res.data);
              });
            }
            for (let d = 0; d < this.docs.length; d++) {
              var doc = {
                name: this.docs[d].name,
                path: this.docs[d].path,
                funding_id: this.info.id,
                created_by: this.currentUser.id,
                updated_by: this.currentUser.id,
                status_id: 1,
                updated_at: new Date(),
              };
              FundingService.createFundingDoc(doc).then(() => {
                // console.log(res.data);
              });
            }
          });
          alert("บันทึกสำเร็จ");
          this.getParticipations();
        }
      }
    },
    getFundings(type) {
      this.data = [];
      FundingService.getFundings(type, this.search).then((res) => {
        // console.log(res.data);
        this.data = res.data;
      });
    },
    getDocs() {
      FundingService.getDocFunding(this.info.id, 2).then((res) => {
        console.log(res.data);
        this.docs = res.data;
      });
    },
    getNewTypes() {
      NewsService.getNewTypes().then((res) => {
        // console.log(res.data);
        this.news_type = res.data;
      });
    },
    deleteDoc() {
      // console.log(this.docid);
      for (let t = 0; t < this.docs.length; t++) {
        if (this.docs[t].id == this.docid) {
          this.docs.splice(t, 1);
          document.getElementById("closedeletedoc").click();
        }
      }
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
      this.filename = selectedFile.name;
      // console.log(selectedFile);
    },
    onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      //  sending file to the backend
      var http = LinkService.getLink() + "/upload?name=" + this.filename;
      // var http ='http://localhost:8080/upload'
      axios
        .post(http, formData)
        .then(() => {
          // console.log(res.data.name);
          this.docs.push({
            id: this.docs.length + 1,
            name: this.filename,
            path: LinkService.getLink() + "/uploads/" + this.filename,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getResearchs() {
      MasterService.getResearchs().then((res) => {
        // console.log(res.data);
        this.researchs = res.data;
      });
    },
    codeAndName(item) {
      console.log(item);
      return `${item.name_th}`;
    },
    getAuthors() {
      AuthorsService.getAuthors().then((res) => {
        // console.log(res.data);
        this.authors = res.data;
      });
    },
    getAuthorTypes() {
      MasterService.getAuthors().then((res) => {
        // console.log(res.data);
        this.authorstypes = res.data;
      });
    },
    getParticipations() {
      MasterService.getParticipations().then((res) => {
        this.participations = res.data;
      });
    },
    getResearchsDatabase() {
      MasterService.getResearchsDatabase().then((res) => {
        this.researchsdatabase = res.data;
      });
    },
    getFunds() {
      MasterService.getFunds().then((res) => {
        this.funds = res.data;
      });
    },
    getFields() {
      MasterService.getFields().then((res) => {
        this.fields = res.data;
      });
    },
    addauthor() {
      this.authorlist.push({
        name: "",
        field: "",
        type_of_participation_id: "",
        proportion: "",
      });
    },
    filteredAuthor() {
      this.filteredList = [];
      this.filteredList = this.authors.filter((post) => {
        // console.log(post.name_th);
        return post.name_th
          .toLowerCase()
          .includes(this.info.leader.toLowerCase());
      });
    },
  },
  mounted() {
    if (this.currentUser.user_type_id == 1) {
      this.getFundings("");
    } else {
      this.getFundings(this.currentUser.id);
    }

    this.getNewTypes();
    this.getFunds();
    this.getParticipations();
    this.getAuthors();
    this.filteredAuthor();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    // filteredList() {
    //   return this.authors.filter((post) => {
    //     console.log(this.info.leader);
    //     this.filteredList = post.name_th.toLowerCase().includes(this.info.leader.toLowerCase());
    //   });
    // },
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
span {
  font-size: 1.5rem;
}
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}
.upload-button {
  width: 7rem;
  padding: 0.5rem;
  background-color: #278be9;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 1rem;
}

.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
.quillWrapper {
  margin: 0px;
}
.ql-toolbar {
  margin: 0px;
}
.input-group {
  margin: 0px;
}
.mx-calendar {
  margin: 0px;
  padding: 0px;
  height: 320px !important;
}
.mx-datepicker {
  margin: 0px;
}
.mx-input {
  height: calc(2.875rem + 2px);
}
.mx-input-wrapper {
  margin: 0px;
}
#app {
  font-size: 1.5rem;
}
.btn {
  font-size: 1.5rem;
}
.form-control-md {
  font-size: 1.5rem;
}
.form-select-md {
  font-size: 1.5rem;
}
.page-item.active .page-link {
  background-color: #c49dd8;
  border-color: #c49dd8;
}
jw-pagination {
  color: white;
}
.card {
  padding: 0;
  margin: 0px;
  width: 100% !important;
  max-width: 100%;
}
input,
div,
button {
  margin-top: 0px;
  font-size: 1.5rem;
}
th {
  font-size: 1.5rem;
}
td {
  font-size: 1.5rem;
}
.custom-file-label {
  height: 29px;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ddcfe5;
  border-color: #ddcfe5;
}
.fa-plus:before {
  content: "\f067";
  color: white;
}
</style>