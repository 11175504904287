import http from "../http-common";

class UserService {
  getUser(id) {
    return http.get('/user/'+id);
  }
  getUsers() {
    return http.get('/user');
  }
  createUser(data) {
    return http.post('/user', data);
  } 
  updateUser(id,data) {
    return http.put(`/user/${id}`, data);
  }
  getMenus(name) {
    return http.get('/menus?name='+name);
  }
  getUsersType() {
    return http.get('/user_type');
  }
  getPersonnelType() {
    return http.get('/personnel_type');
  }  
}

export default new UserService();