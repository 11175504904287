<template>
  <div id="app" style="padding: 0px">
    <div class="news-tab" style="margin: 5px">
      <h2>จัดการผู้ใช้งาน</h2>
      <div class="container mt-3" style="display: block" v-if="type == 0">
        <div style="display: flex; justify-content: flex-end">
          <div>
            <a href="/User/0">
            <button
              type="button"
              class="btn btn-success"
            >
              <i class="fa fa-plus" style="font-size: 12px"></i>
            </button></a>
          </div>
        </div>
        <table class="table table-striped mt-3">
          <thead>
            <tr>
               <th scope="col">ชื่อผู้ใช้งาน</th>
              <!-- <th scope="col">ID</th>
              <th scope="col">ชื่อนักวิจัย</th>
              <th scope="col">ชื่อภาษาอังกฤษ</th>
              <th scope="col">สาขา</th>
              <th scope="col">Scopus Number</th>
              <th scope="col">Google Scholar Website</th>
              <th scope="col">ตำแหน่ง</th>
              <th scope="col">Scopus H-index</th>
              <th scope="col">Scopus Citation</th>
              <th scope="col">Google H-index</th>
              <th scope="col">Google Citation</th> -->
              <th scope="col">จัดการ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d, i) in pageOfItems" :key="i"> 
              <th scope="row">{{ d.title }} {{ d.first_name }} {{ d.last_name }}</th>
              <!-- <th scope="row">{{ d.code }}</th>
              <td>{{ d.name_th }}</td>
              <td>{{ d.name_en }}</td>
              <td>{{ d.type_of_field }}</td>
              <td>{{ d.scopus_number || "-" }}</td>
              <td>{{ d.scholar_website || "-" }}</td>
              <td>{{ d.type_of_position }}</td>
              <td>{{ d.scopus_hindex || "-" }}</td>
              <td>{{ d.scopus_citation || "-" }}</td>
              <td>{{ d.google_hindex || "-" }}</td>
              <td>{{ d.google_citation || "-" }}</td> -->
              <td>
                <a :href="'/User/'+d.id">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                >
                  <i class="fa fa-rectangle-list" style="font-size: 12px"></i>
                </button></a>&nbsp;
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  @click="getdata(d.id)"
                >
                  <i class="fa fa-ban" style="font-size: 12px"></i>
                </button>&nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteData"
                    @click="getdata('research', d.id)"
                  >
                    <i class="fa fa-trash" style="font-size: 12px"></i>
                  </button>
              </td>
            </tr>
          </tbody>
        </table>
          <div class="row mt-10" align="right">
                  <div class="col-md-12">
                    <jw-pagination
                      :items="data"
                      @changePage="onChangePage"
                      :labels="customLabels"
                    ></jw-pagination>
                  </div>
                </div>
      </div>
      <div class="container mt-3" style="display: block" v-if="type == 1">
        <button type="button" class="btn btn-primary" @click="back()">
          ย้อนกลับ
        </button>
        <div class="form-group">
          <label for="exampleInputPassword1">ID</label>
          <input
            v-model="info.code"
            type="text"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="ID"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">ชื่อนักวิจัย</label>
          <input
            v-model="info.name_th"
            type="text"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="ชื่อนักวิจัย"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">ชื่อภาษาอังกฤษ</label>
          <input
            v-model="info.name_en"
            type="text"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="ชื่อภาษาอังกฤษ"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">สาขา</label>
          <select class="form-select mb-3" v-model="info.type_of_field_id">
            <option v-for="(d, i) in fields" v-bind:value="d.id" :key="i">
              {{ d.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Scopus Number</label>
          <input
            v-model="info.scopus_number"
            type="text"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Scopus Number"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Google Scholar Website</label>
          <input
            v-model="info.scholar_website"
            type="text"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Google Scholar Website"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">ตำแหน่ง</label>
          <select class="form-select mb-3" v-model="info.type_of_position_id">
            <option v-for="(d, i) in positions" v-bind:value="d.id" :key="i">
              {{ d.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Scopus H-index</label>
          <input
            v-model="info.scopus_hindex"
            type="number"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Scopus H-index"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Scopus Citation</label>
          <input
            v-model="info.scopus_citation"
            type="number"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Scopus Citation"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Google H-index</label>
          <input
            v-model="info.google_hindex"
            type="number"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Google H-index"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Google Citation </label>
          <input
            v-model="info.google_citation"
            type="number"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Google Citation "
          />
        </div>
        <button type="button" class="btn btn-primary mt-3" @click="save()">
          บันทึก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import MasterService from "../services/MasterService";
import AuthorsService from "../services/AuthorsService";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  name: "app",
  components: {},
  data() {
    return {
      data: [],
      content: "",
      info: {},
      type: 0,
      fields: [],
      positions: [],
      customLabels,
      pageOfItems: [],
    };
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    back() {
      this.type = 0;
      this.getUsers();
    },
    getdata(id) {
      this.modalVisible = false;
      // console.log(type);
      if (id != 0) {
        AuthorsService.getAuthor(id).then((res) => {
          // console.log(res.data);
          this.info = res.data;
          this.info.id == res.data.id;
        });
      } else {
        this.info.id = 0;
      }
      this.type = 1;
    },
    save() {
      console.log(this.info);
      if (this.info.code == "") {
        alert("กรุณากรอก ID");
      } else if (this.info.name_th == "") {
        alert("กรุณากรอกชื่อนักวิจัย");
      } else if (this.info.name_en == "") {
        alert("กรุณากรอกชื่อภาษาอังกฤษ");
      } else if (this.info.type_of_field_id == "") {
        alert("กรุณาเลือกสาขา");
      }
      // else if (this.info.scopus_number == "") {
      //   alert("กรุณากรอก Scopus Number");
      // }
      // else if (this.info.scholar_website == "") {
      //   alert("กรุณากรอก Google Scholar Website");
      // }
      else if (this.info.type_of_position_id == "") {
        alert("กรุณากรอกเลือกตำแหน่ง");
      }
      // else if (this.info.scopus_hindex == "") {
      //   alert("กรุณากรอก Scopus H-index");
      // }else if (this.info.scopus_citation == "") {
      //   alert("กรุณากรอก Scopus Citation");
      // }else if (this.info.google_hindex == "") {
      //   alert("กรุณากรอก Google H-index");
      // }else if (this.info.google_citation == "") {
      //   alert("กรุณากรอก Google Citation ");
      // }
      else {
        var authors = {
          code: this.info.code,
          name_th: this.info.name_th,
          name_en: this.info.name_en,
          type_of_field_id: this.info.type_of_field_id,
          scopus_number: this.info.scopus_number,
          scholar_website: this.info.scholar_website,
          type_of_position_id: this.info.type_of_position_id,
          scopus_hindex: this.info.scopus_hindex,
          scopus_citation: this.info.scopus_citation,
          google_hindex: this.info.google_hindex,
          google_citation: this.info.google_citation,
          created_by: this.currentUser.id,
          updated_by: this.currentUser.id,
        };
        console.log(authors);
        if (this.info.id == 0) {
          AuthorsService.createAuthor(authors).then((res) => {
            console.log(res.data);
          });
          alert("บันทึกสำเร็จ");
        } else {
          AuthorsService.updateAuthor(this.info.id, authors).then((res) => {
            console.log(res.data);
          });
          alert("บันทึกสำเร็จ");
        }
      }
    },
    getUsers(type) {
      UserService.getUsers(type).then((res) => {
        console.log(res.data);
        this.data = res.data;
      });
    },
    getFields() {
      MasterService.getFields().then((res) => {
        this.fields = res.data;
      });
    },
    getPositions() {
      MasterService.getPositions().then((res) => {
        this.positions = res.data;
      });
    },
  },
  mounted() {
    this.getUsers("");
    this.getFields();
    this.getPositions();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
span {
  font-size: 1.5rem;
}
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}
.upload-button {
  width: 7rem;
  padding: 0.5rem;
  background-color: #278be9;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 1rem;
}

.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
.quillWrapper {
  margin: 0px;
}
.ql-toolbar {
  margin: 0px;
}
.input-group {
  margin: 0px;
}
</style>