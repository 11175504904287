<template>
  <div id="app" style="padding: 0px">
    <div class="row mb-5">
      <div class="col-md-12" style="padding: 15px">
        <div class="card">
          <div class="card-header">รายละเอียดโครงการวิจัย</div>
          <div class="card-body">
            <div class="container mt-3" style="display: block">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-row align-items-center">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1"
                        >ปี [พ.ศ.]</span
                      >
                      <input
                        type="text"
                        v-model="search"
                        class="form-control form-control-md"
                        placeholder="พ.ศ."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />

                      <div class="input-group-append" style="margin-top: 0px">
                        <button
                          class="btn btn-info"
                          type="button"
                          style="color: white"
                          @click="searchdata()"
                        >
                          <i class="fa fa-search" style="font-size: 12px"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <h3>
                    งานวิจัยหรือโครงการที่ปิดแล้ว 0
                    &nbsp;&nbsp;&nbsp;งานวิจัยหรือโครงการที่ยังไม่ปิด 0
                  </h3>
                </div>
              </div>

              <table class="table table-striped mt-5">
                <thead>
                  <tr>
                    <th scope="col">เลขสัญญา</th>
                    <th scope="col">ชื่อโครงการวิจัย</th>
                    <th scope="col">สถานะ</th>
                    <th scope="col">เวลาที่ขออนุมัติ</th>
                    <th scope="col">เวลาที่สิ้นสุดสัญญาทุน</th>
                    <th scope="col">วันที่ปิดโครงการ</th>
                    <th scope="col">จัดการ</th>
                    <th scope="col">หมายเหตุ</th>
                  </tr>
                </thead>
                <tbody v-if="!data.length == 0">
                  <tr v-for="(d, i) in pageOfItems" :key="i">
                    <th scope="row">{{ d.contract }}</th>
                    <th scope="row">{{ d.project_name_th }}</th>
                    <th scope="row"></th>
                    <th scope="row"></th>
                    <th scope="row"></th>
                    <th scope="row"></th>
                    <td>
                      <a :href="'/Funding/' + d.id">
                        <button type="button" class="btn btn-outline-primary">
                          <i
                            class="fa fa-edit"
                            style="font-size: 12px"
                          ></i></button
                      ></a>
                    </td>
                    <th scope="row">
                      {{ d.remark || "-" }}
                    </th>
                  </tr>
                </tbody>
              </table>
              <div
                class="col-md-12"
                style="text-align: center"
                v-if="data.length == 0"
              >
                ไม่พบข้อมูล
              </div>
              <div class="row mt-5" align="right">
                <div class="col-md-12">
                  <jw-pagination
                    :items="data"
                    @changePage="onChangePage"
                    :labels="customLabels"
                  ></jw-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row mb-5">
        <div class="col-md-12" style="padding: 15px">
          <div class="card">
            <div class="card-header">รายละเอียดผลงานเผยแพร่</div>
            <div class="card-body">
              <div class="container mt-3" style="display: block">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-row align-items-center">
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1"
                          >ปี [พ.ศ.]</span
                        >
                        <input
                          type="text"
                          v-model="searchpub"
                          class="form-control form-control-md"
                          placeholder="พ.ศ."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />

                        <div class="input-group-append" style="margin-top: 0px">
                          <button
                            class="btn btn-info"
                            type="button"
                            style="color: white"
                            @click="searchdatapub()"
                          >
                            <i class="fa fa-search" style="font-size: 12px"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <h3>งานวิจัยหรือโครงการที่ปิดแล้ว 0</h3>
                  </div>
                </div>
                <table class="table table-striped mt-3">
                  <thead>
                    <tr>
                      <th scope="col">ชื่อผลงานวิจัย</th>
                      <th scope="col">เลขที่ผลงานวิจัย</th>
                      <th scope="col">สถานะ</th>
                      <th scope="col">วันที่ขออนุมัติเคลม</th>
                      <th scope="col">วันที่ยื่นเครม</th>
                      <th scope="col">วันที่เผยแพร่ผลงาน</th>
                      <th scope="col">จัดการ</th>
                      <th scope="col">หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody v-if="!datapub.length == 0">
                    <tr v-for="(d, i) in pageOfItemspub" :key="i">
                      <td>{{ d.name_th }}</td>
                      <th scope="row"></th>
                      <th scope="row"></th>
                      <th scope="row"></th>
                      <th scope="row"></th>
                      <th scope="row">
                        {{ convertdatetothai(d.publication_date) }}
                      </th>
                      <td>
                        <a :href="'/Publications/' + d.id">
                          <button type="button" class="btn btn-outline-primary">
                            <i
                              class="fa fa-edit"
                              style="font-size: 12px"
                            ></i></button
                        ></a>
                      </td>
                      <th scope="row">
                        {{ d.remark || "-" }}
                      </th>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="col-md-12"
                  style="text-align: center"
                  v-if="datapub.length == 0"
                >
                  ไม่พบข้อมูล
                </div>
                <div class="row mt-5" align="right">
                  <div class="col-md-12">
                    <jw-pagination
                      :items="datapub"
                      @changePage="onChangePagepub"
                      :labels="customLabelspub"
                    ></jw-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};
const customLabelspub = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};
import FundingService from "../services/FundingService";
import "vue-search-select/dist/VueSearchSelect.css";
import PublicationService from "../services/PublicationService";

export default {
  name: "app",
  components: {
    // ModelListSelect
  },
  data() {
    return {
      data: [],
      datapub: [],
      content: "",
      info: { leader: "" },
      type: 0,
      news_type: [],
      selectedFile: "",
      filename: "Choose file",
      docs: [],
      docid: "",
      modalVisible: false,
      researchs: [],
      authors: [],
      participations: [],
      researchsdatabase: [],
      fields: [],
      authorstypes: [],
      authorlist: [],
      leader: "",
      customLabels,
      customLabelspub,
      pageOfItems: [],
      pageOfItemspub: [],
      search: "",
      searchpub: "",
      filteredList: [],
    };
  },
  methods: {
    searchdata() {
      if (this.currentUser.user_type_id == 1) {
        this.getFundings("");
      } else {
        this.getFundings(this.currentUser.id);
      }
    },
    searchdatapub() {
      if (this.currentUser.user_type_id == 1) {
        this.getPublications("");
      } else {
        this.getPublications(this.currentUser.id);
      }
    },
    onChangePagepub(pageOfItemspub) {
      // update page of items
      this.pageOfItemspub = pageOfItemspub;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    convertdatetothai(datetimes) {
      if (datetimes) {
        const date = new Date(datetimes);
        var result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        // result =
        //   result + " " + date.getHours() + ":" + date.getMinutes() + " น.";
        return result;
      } else {
        return "-";
      }
    },
    getFundings(type) {
      this.data = [];
      FundingService.getFundings(type, this.search).then((res) => {
        console.log(res.data);
        this.data = res.data;
      });
    },
    getPublications(type) {
      PublicationService.getPublications(type, this.searchpub).then((res) => {
        console.log(res.data);
        this.datapub = res.data;
      });
    },
  },
  mounted() {
    if (this.currentUser.user_type_id == 1) {
      this.getFundings("");
      this.getPublications("");
    } else {
      this.getFundings(this.currentUser.id);
      this.getPublications(this.currentUser.id);
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    // filteredList() {
    //   return this.authors.filter((post) => {
    //     console.log(this.info.leader);
    //     this.filteredList = post.name_th.toLowerCase().includes(this.info.leader.toLowerCase());
    //   });
    // },
  },
};
</script>

<style>
.navbar {
  background-color: #09cc68;
}
.header {
  padding: 5px;
  background-color: #09cc68;
  color: white;
}
.text {
  font-size: 15px;
  /* min-height: 1em; */
  margin: 5px;
  vertical-align: middle;
}
footer {
  /* display: grid; */
  text-align: center;
  padding: 30px;
  background: #3e4041;
}
a {
  color: #560e7b !important;
  font-size: 1.5rem;
}
span {
  font-size: 1.5rem;
}
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}
.upload-button {
  width: 7rem;
  padding: 0.5rem;
  background-color: #278be9;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 1rem;
}

.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
.quillWrapper {
  margin: 0px;
}
.ql-toolbar {
  margin: 0px;
}
.input-group {
  margin: 0px;
}
.mx-calendar {
  margin: 0px;
  padding: 0px;
  height: 320px !important;
}
.mx-datepicker {
  margin: 0px;
}
.mx-input {
  height: calc(2.875rem + 2px);
}
.mx-input-wrapper {
  margin: 0px;
}
#app {
  font-size: 1.5rem;
}
.btn {
  font-size: 1.5rem;
}
.form-control-md {
  font-size: 1.5rem;
}
.form-select-md {
  font-size: 1.5rem;
}
.page-item.active .page-link {
  background-color: #c49dd8;
  border-color: #c49dd8;
}
jw-pagination {
  color: white;
}
.card {
  padding: 0;
  margin: 0px;
  width: 100% !important;
  max-width: 100%;
}
input,
div,
button {
  margin-top: 0px;
  font-size: 1.5rem;
}
th {
  font-size: 1.5rem;
}
td {
  font-size: 1.5rem;
}
.custom-file-label {
  height: 29px;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ddcfe5;
  border-color: #ddcfe5;
}
.fa-plus:before {
  content: "\f067";
  color: white;
}
span {
  font-size: 1.5rem !important;
}
</style>