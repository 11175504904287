import http from "../http-common";

class AuthorsService {
  getAuthors(type) {
    return http.get('/authors?type='+type);
  }
  getAuthorByname(name) {
    return http.get('/authors?name='+name);
  }
  getAuthor(id) {
    return http.get('/authors/'+id);
  }
  createAuthor(data) {
    return http.post(`/authors`, data);
  }
  updateAuthor(id,data) {
    return http.put(`/authors/${id}`, data);
  }
}

export default new AuthorsService();